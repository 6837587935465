import { FormEvent, useState, useRef, ChangeEvent } from "react";
import { AccountSettingsForm } from "./AccountSettingsForm";
import {
  DialogContent,
  DialogOverlay,
  DialogButtonGroup,
  DialogButton,
} from "../Dialog";
import { Status } from "./AccountSettings.types";
import styles from "./AccountSettings.module.scss";
import { ReactComponent as LeftArrowIcon } from "@hagerty/design-system/src/assets/icons/16/16-arrow-left.svg";
import { Customer, MutableCustomer } from "shared";
import { formFieldNames } from "./getMutatableCustomer";
import { UnsavedChangesDialog } from "./UnsavedChangesDialog";
import { decodePhoneNumber } from "../../utils/decodePhoneNumber";
import {
  phoneRegExp,
  usPostalCodeRegExp,
  caPostalCodeRegExp,
  caPostalCodeWithSpaceRegExp,
} from "./AccountSettingsRegExps";
import { mt } from "../../utils/tracking";

export type SourceType =
  | "phone number" // Dialog opened via phone number "Edit" button
  | "mailing address" // Dialog opened via mailing address "Edit" button
  | "leave without saving"; // Dialog closed after clicking the "Leave without saving" button

export const logContactInformationModal = (
  sourceType: SourceType,
  errorMessages?: string[]
): void => {
  const modalType = `edit contact information : ${sourceType}`;
  mt.trackPopup(modalType);

  if (errorMessages?.length) {
    errorMessages.forEach((error) =>
      mt.trackError({
        errorType: "account settings",
        errorDetails: error,
      })
    );
  }
};

export type ValidationErrors = {
  firstName?: string;
  lastName?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
};

export type TouchedFields = {
  firstName?: boolean;
  lastName?: boolean;
  state?: boolean;
  postalCode?: boolean;
  phone?: boolean;
};

const areCustomersTheSame = (cust1: MutableCustomer, cust2: MutableCustomer) =>
  formFieldNames.every((key) => cust1[key] === cust2[key]);

type AccountSettingsDialogProps = {
  sourceType: SourceType;
  status: Status;
  customer: Customer;
  onSubmit: (mutableCustomer: MutableCustomer) => Promise<boolean>;
  onCloseDialog: () => void;
};

export const AccountSettingsDialog = ({
  sourceType,
  status,
  customer: initialCustomer,
  onCloseDialog,
  onSubmit,
}: AccountSettingsDialogProps) => {
  const topOfFormRef = useRef<HTMLDivElement>(null);
  const formattedInitialCustomer = {
    ...initialCustomer,
    phone: decodePhoneNumber(initialCustomer.phone),
    postalCode: initialCustomer.postalCode.toUpperCase(),
  };
  const [customer, setCustomer] = useState<Customer>(formattedInitialCustomer);
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false);
  const pending = status === "pending";

  const checkIfDirtyThenClose = () => {
    const isDirty = !areCustomersTheSame(formattedInitialCustomer, customer);
    if (isDirty) {
      setShowUnsavedChangesDialog(true);
      return;
    }
    onCloseDialog();
  };

  function getValidationErrors() {
    const errors: ValidationErrors = {};
    if ((submitted || touchedFields.firstName) && !customer.firstName)
      errors.firstName = "Please enter your first name";

    if ((submitted || touchedFields.lastName) && !customer.lastName)
      errors.lastName = "Please enter your last name";

    const postalRegex =
      customer.country === "USA" ? usPostalCodeRegExp : caPostalCodeRegExp;

    if (
      (submitted || touchedFields.postalCode) &&
      !RegExp(postalRegex).test(customer.postalCode)
    ) {
      errors.postalCode =
        "Please provide a valid postal code based on the address country";
    }

    if (
      (submitted || touchedFields.phone) &&
      !RegExp(phoneRegExp).test(customer.phone)
    ) {
      errors.phone = "Please provide a valid 10-digit phone number";
    }

    const errorMessages = Object.values(errors);
    if (errorMessages.length > 0) {
      logContactInformationModal(sourceType, errorMessages);
    }

    return errors;
  }

  function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const newCustomer: Customer = {
      ...customer,
      [event.target.id]: event.target.value,
    };
    setCustomer(newCustomer);
  }

  function handleCanadaPostalCode() {
    if (customer.country === "USA") return;

    const postalCode = customer.postalCode.trim();
    const hasSpace = RegExp(caPostalCodeWithSpaceRegExp).test(postalCode);

    if (hasSpace) return;

    const firstHalf = postalCode.substring(0, 3);
    const secondHalf = postalCode.substring(3);
    customer.postalCode = `${firstHalf} ${secondHalf}`;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSubmitted(true);
    const errors = getValidationErrors();
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      handleCanadaPostalCode();
      // Send customer without country to onSubmit since the user can't change their country.
      const { country, ...customerWithoutCountry } = customer;
      onSubmit(customerWithoutCountry);
    } else {
      topOfFormRef.current?.scrollIntoView();
    }
  }

  function onBlur({
    target,
  }: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) {
    setTouchedFields((cur) => ({
      ...cur,
      [target.id]: true,
    }));
  }

  const validationErrors = getValidationErrors();

  return (
    <>
      <DialogOverlay size="medium" onDismiss={checkIfDirtyThenClose}>
        <form onSubmit={handleSubmit}>
          <DialogContent
            title="Contact information"
            role="dialog"
            className={styles.accountSettings}
            onDismiss={checkIfDirtyThenClose}
            showBusy={pending}
            closeBtnLabel="Close contact information dialog"
            footer={
              <DialogButtonGroup>
                <DialogButton
                  variant="outline"
                  aria-label="Cancel update and close this window"
                  onClick={checkIfDirtyThenClose}
                  disabled={pending}
                  type="button"
                >
                  <LeftArrowIcon
                    className="button_primary__icon icon"
                    aria-hidden="true"
                  />
                  Back
                </DialogButton>
                <DialogButton
                  type="submit"
                  aria-label="Save contact information"
                  disabled={pending}
                >
                  {pending ? "Saving..." : "Save"}
                </DialogButton>
              </DialogButtonGroup>
            }
          >
            <div ref={topOfFormRef}>
              <AccountSettingsForm
                customer={customer}
                setCustomer={setCustomer}
                validationErrors={validationErrors}
                pending={pending}
                onChange={onChange}
                onBlur={onBlur}
                submitted={submitted}
              />
            </div>
          </DialogContent>
        </form>
      </DialogOverlay>
      {showUnsavedChangesDialog && (
        <UnsavedChangesDialog
          onClose={() => {
            setShowUnsavedChangesDialog(false);
            logContactInformationModal("leave without saving");
            onCloseDialog();
          }}
          onCancel={() => {
            setShowUnsavedChangesDialog(false);
            logContactInformationModal(sourceType);
          }}
        />
      )}
    </>
  );
};
