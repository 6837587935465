import withSuspense from "../../hocs/withSuspense";
import { LoadingRow } from "../LoadingRow";
import { BillingPayment as BillingPaymentUnwrapped } from "./BillingPayment";
import { ErrorRow } from "../ErrorRow";

type BillingPaymentFallbackProps = {
  resetErrorBoundary: () => void;
};

const BillingPaymentFallback = ({
  resetErrorBoundary,
}: BillingPaymentFallbackProps) => {
  return <ErrorRow resetErrorBoundary={resetErrorBoundary} />;
};

export const BillingPayment = withSuspense(BillingPaymentUnwrapped, {
  FallbackComponent: BillingPaymentFallback,
  loading: <LoadingRow />,
});
