import { Benefits, getCoverageUnit } from "shared";
import { useCountry } from "../../app/Providers/AppContext";

type PlanProps = {
  benefits: Benefits;
};

export const Plan = ({ benefits }: PlanProps) => {
  const { distance, coversAllVehicles } = benefits;
  const coveredVehiclesType = coversAllVehicles ? "all" : "classic";
  const country = useCountry();
  const unit = getCoverageUnit(country);

  return (
    <>
      Plan: {`${distance} ${unit} per tow for ${coveredVehiclesType} vehicles`}
    </>
  );
};
