import { useState } from "react";
import { ESignDocument, getStateName } from "shared";
import { useEmail } from "../../app/Providers/AppContext";
import { sleep } from "../../utils/sleep";
import { Status } from "../SignDocumentsDialog";
import { postESignDocument } from "./postESignDocument";

export type UseESign = {
  shouldShowDialog: boolean;
  policyName: string;
  email: string;
  send: (eSignDocument: ESignDocument) => void;
  resend: () => void;
  dismiss: () => void;
  status: Status;
};

export const useESign = (): UseESign => {
  const email = useEmail();
  const [dialogStatus, setDialogStatus] = useState<Status>("pending");

  const [requestInProgress, setRequestInProgress] =
    useState<ESignDocument | null>(null);

  async function handleSignDocumentClick(eSignDocument: ESignDocument) {
    setRequestInProgress(eSignDocument);

    setDialogStatus("pending");
    // Sleeping 2 seconds per Praneet to assure the user can see and absorb the loading screen
    // Ignoring 2nd response payload since it's merely there to enforce the 2 second delay
    const [isSuccess] = await Promise.all([
      postESignDocument(eSignDocument),
      sleep(2000),
    ]);
    setDialogStatus(isSuccess ? "success" : "error");
  }

  const eSign: UseESign = {
    shouldShowDialog: !!requestInProgress,
    policyName:
      (requestInProgress && getStateName(requestInProgress.state)) ?? "",
    email: email,
    status: dialogStatus,
    send: (eSignDocument: ESignDocument) => {
      handleSignDocumentClick(eSignDocument);
    },
    resend: () => {
      if (requestInProgress) {
        handleSignDocumentClick(requestInProgress);
      }
    },
    dismiss: () => setRequestInProgress(null),
  };

  return eSign;
};
