import { SessionTimeoutWarning } from "@hagerty/self-service-components/base/sessionTimeoutWarning";
import { SessionTimeoutMonitorProps } from "./SessionTimeoutMonitor.types";
import { useLogoutId, useUrls } from "../../app/Providers/AppContext";
import { Provider } from "@hagerty/self-service-components";
import styles from "./SessionTimeoutMonitor.module.scss"
/**
 * Monitors if the user is idle for a specified period of time and logs the user out if the timeout expires.
 * This is a wrapper for the SessionTimeoutWarning component.
 */
export const SessionTimeoutMonitor = ({
  idleTime,
  timeoutDuration,
}: SessionTimeoutMonitorProps) => {
  const urls = useUrls();
  const logoutId = useLogoutId();

  return (
    <Provider>
      <SessionTimeoutWarning
        idleTime={idleTime}
        timeoutDuration={timeoutDuration}
        onTimeoutExpired={logout}
        className={styles.content}
      />
    </Provider>
  );

  function logout(): void {
    const logoutUrl = urls.account.logout(window.location.origin, logoutId);
    window.location.assign(logoutUrl);
  } 
};
