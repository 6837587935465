import { useRef } from "react";
import { Highlight } from "../Highlight";
import styles from "./DriversClubLayout.module.scss";
import { Section } from "../Section";
import {
  Benefits,
  Hdc1Dot0MembershipLevel,
  Hdc1Dot5MembershipLevel,
} from "shared";
import { useActiveSection } from "../../hooks/useActiveSection";
import { SubHeader } from "../SubHeader";
import { SectionHeader } from "../SectionHeader";
import { EffectiveFrom } from "../EffectiveFrom";
import { Plan } from "./Plan";

type DriversClubLayoutProps = {
  children: React.ReactNode;
  membershipLevel?: Hdc1Dot0MembershipLevel | Hdc1Dot5MembershipLevel;
  membershipId?: string;
  effectiveDate?: string;
  expirationDate?: string;
  benefits?: Benefits;
  isVip?: boolean;
};

export function DriversClubLayout({
  children,
  membershipLevel,
  membershipId,
  effectiveDate,
  expirationDate,
  benefits,
}: DriversClubLayoutProps) {
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  useActiveSection(headerRef, "hdc");

  const headingSuffix = membershipLevel === "VIP" ? " (VIP)" : "";

  return (
    <Section ariaLabel="Drivers Club">
      <SectionHeader
        heading={`Drivers Club${headingSuffix}`}
        id="hdc"
        headerRef={headerRef}
      >
        {membershipId && (
          <Highlight className={styles.highlight} ariaLabel="Membership number">
            #{membershipId}
          </Highlight>
        )}
      </SectionHeader>

      <SubHeader
        elements={[
          effectiveDate && (
            <EffectiveFrom
              effectiveDate={effectiveDate}
              expirationDate={expirationDate}
            />
          ),
          benefits && <Plan benefits={benefits} />,
        ]}
      />

      <div className="grid grid-wrap collapsed-gutters login-data-table_row">
        {children}
      </div>
    </Section>
  );
}
