import { Alert, Button } from "@hagerty/react-components";
import styles from "./EnthusiastPolicyAlert.module.scss";
import { phoneNumbers } from "shared";
import { Link } from "../Link";

export const EnthusiastPolicyAlert = () => {
  return (
    <Alert
      classNames={styles.root}
      alertType="info"
      hasIcon
      messageText={
        <>
          <div className={styles.message}>
            <span className={styles.boldMessage}>
              You are participating in the Hagerty Enthusiast+ program.{" "}
            </span>
            In order to pay your bill, edit your policy, file a claim, or access
            other policy documentation, please call{" "}
            <Link phoneNumber={phoneNumbers.apex} className={styles.link} />.
          </div>
          <Button href={`tel:${phoneNumbers.apex}`} buttonType="Dark Outline">
            Call now
          </Button>
        </>
      }
    />
  );
};
