import { useEffect } from "react";
import { useFetchApi } from "../../hooks/useFetchApi";
import { DriversClub } from "shared";
import { useIsHdcMember } from "../../app/Providers/DriversClubContext";
import { useLogHasHdc } from "../../app/Providers/LogOnReadyContext";

export const useDriversClub = (): DriversClub => {
  const { setIsHdcMember } = useIsHdcMember();
  const logHasHdc = useLogHasHdc();
  const { data: driversClub } = useFetchApi<DriversClub>("hdc", 2);
  const hasDriversClub = !!driversClub;

  useEffect(() => {
    setIsHdcMember(hasDriversClub);
    logHasHdc(hasDriversClub);
  }, [hasDriversClub, logHasHdc, setIsHdcMember]);
  return driversClub;
};
