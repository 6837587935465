import { Card } from "../Card";
import styles from "./Tile.module.scss";
import cx from "clsx";
import { TagAction } from "../../types/Tag.types";
import { ForwardedRef, ReactNode, forwardRef } from "react";

interface SharedTileProps {
  /** CSS class to apply to the root element */
  className?: string;

  /** Call to action, displayed in blue so it looks like a link (even though the entire tile is clickable) */
  cta: string;

  /** Icon to display */
  icon: React.ReactNode;

  /** Add additional icon to cta  */
  ctaIcon?: ReactNode;

  isLoading?: boolean;
}

export interface DivTileProps extends SharedTileProps {
  /** Aria-label isn't valid for Divs */
  ariaLabel?: never;

  /** Div can't have an href or tagAction */
  href?: never;
  tagAction?: never;

  /** Set to true to open link in new tab */
  newTab?: boolean;

  /** Div can't have an onClick */
  onClick?: never;

  isLoading?: boolean;
}

export interface AnchorTileProps extends SharedTileProps {
  /** Optional aria-label to apply to the root element */
  ariaLabel?: string;

  /** Href to load when the tile is clicked */
  href: string;

  /** Tracking name and action */
  tagAction?: TagAction;

  /** Set to true to open link in new tab */
  newTab?: boolean;

  /** Anchor can have an onClick */
  onClick?: () => void;
}

export interface ButtonTileProps extends SharedTileProps {
  /** Optional aria-label to apply to the root element */
  ariaLabel?: string;

  /** Button doesn't support an href */
  href?: never;

  /** Tracking name and action */
  tagAction?: TagAction;

  /** New tab isn't supported for a button */
  newTab?: never;

  /** Function to call when the tile is clicked */
  onClick: () => void;
}

export type TileProps = DivTileProps | AnchorTileProps | ButtonTileProps;

export const Tile = forwardRef(
  (
    { icon, className, ariaLabel, cta, ctaIcon, isLoading, ...rest }: TileProps,
    ref: ForwardedRef<any>
  ) => {
    return (
      <Card
        isLoading={isLoading}
        ref={ref}
        ariaLabel={ariaLabel || cta}
        className={cx(styles.card, className)}
        {...rest}
      >
        <div className={styles.iconWrapper}>{icon}</div>
        <p className={styles.cta}>
          {cta}
          {ctaIcon}
        </p>
      </Card>
    );
  }
);
