import { useEffect, useRef } from "react";
import { useFetchApi } from "../../hooks/useFetchApi";
import { useIsPolicyHolder } from "../../app/Providers/PolicyContext";
import { useLogIsPolicyHolder } from "../../app/Providers/LogOnReadyContext";
import { Policy } from "@hagerty/self-service-components/api/types";

export const useInsurance = () => {
  const initialized = useRef(false);
  const { setIsPolicyHolder } = useIsPolicyHolder();
  const logIsPolicyHolder = useLogIsPolicyHolder();

  const { data: policies } = useFetchApi<Policy[]>("insurance", 2);
  const hasPolicies = policies.length > 0;

  useEffect(() => {
    // Policies are only loaded once, so run this effect once after policies are loaded.
    if (!initialized.current) {
      setIsPolicyHolder(hasPolicies);
      logIsPolicyHolder(hasPolicies);
      initialized.current = true;
    }
  }, [hasPolicies, logIsPolicyHolder, setIsPolicyHolder]);

  return policies;
};
