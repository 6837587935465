import { ReactNode } from "react";
import style from "./Welcome.module.scss";

type WelcomeProps = {
  children: ReactNode;
};

export const Welcome = ({ children }: WelcomeProps) => (
  <div className={`section ${style.welcomeContainer}`}>
    <div className={style.sectionHead}>
      <h1 aria-label="Hagerty Account Profile" className="section__title">
        Welcome, {children}
      </h1>
    </div>
  </div>
);
