import withSuspense from "../../hocs/withSuspense";
import { Alerts as AlertsUnwrapped } from "./Alerts";

const Noop = () => null;

export const Alerts = withSuspense(AlertsUnwrapped, {
  // Per Praneet, if the API call fails, we should silently fail.
  // This way the user's experience isn't negatively impacted if alerts can't be displayed.
  // If one of the provider API calls fails on the BFF, the failure is logged there.
  FallbackComponent: Noop,
  loading: <Noop />,
});
