import { ReactNode, useRef } from "react";
import { Section } from "../Section";
import { SectionHash } from "../../app/Providers/ActiveSectionContext";
import { useActiveSection } from "../../hooks/useActiveSection";
import { SectionHeader } from "../SectionHeader";

type RegionProps = {
  title: string;
  hash: SectionHash;
  belowHeaderSlot?: ReactNode;
  contentSlot: ReactNode;
};

/** Renders one of the app's four main regions */
export const Region = ({
  title,
  hash,
  belowHeaderSlot,
  contentSlot,
}: RegionProps) => {
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  useActiveSection(headerRef, hash);

  return (
    <Section ariaLabel={title}>
      <SectionHeader heading={title} id={hash} headerRef={headerRef} />
      {belowHeaderSlot}
      <div className="grid grid-wrap collapsed-gutters login-data-table_row">
        {contentSlot}
      </div>
    </Section>
  );
};
