import { Country } from "@hagerty/self-service-components/api/types";
import { Environment } from "../";
import { PolicySource } from "./PolicySource.types";
import { DocumentTag } from "@hagerty/customer-insurance-gateway-client";

export type URLs = {
  home: string;
  billing: {
    managePayments: string;
    payBill: string;
  };
  hdc: {
    seeAllPlans: string;
    redeemBenefits: string;
    upgradeOrCancel: string;
    // This accepts an origin because we need to use window.location.origin as the root for this call so it's directed to the BFF (which serves the front-end).
    viewMembershipCard: (origin: string) => string;
  };
  insurance: {
    addVehicle: (
      policyNumber: string,
      policyCountry: Country,
      addVehicle: string
    ) => string;
    editVehicle: (
      policyNumber: string,
      policyItemNumber: number,
      policyCountry: Country
    ) => string;
    removeVehicle: (
      policyNumber: string,
      policyItemNumber: string,
      policyCountry: Country,
      hasTooManyVehiclesOnSinglePolicy: boolean
    ) => string;
    fileClaim: (policyNumber: string, policyCountry: Country) => string;
    newFileClaim: (policyCountry: Country) => string;
    viewPolicy: (
      policyNumber: string,
      policyCountry: Country,
      isStateFarm?: boolean
    ) => string;
    updatePolicy: (policyCountry: Country) => string;
    getQuote: (siteCountry: Country) => string;
    connectPolicy: (hasPolicy: boolean) => string;
    cancelPolicy: (policyCountry: Country) => string;
    getCardUrl: (
      origin: string,
      policyCountry: Country,
      policyNumber: string
    ) => string;
    getDocumentUrl: (
      origin: string,
      policySource: PolicySource,
      document: DocumentTag,
      policyNumber: string
    ) => string;
    policy: (
      origin: string,
      policyCountry: Country,
      policyNumber: string
    ) => string;
    billingStatements: (policyNumber: string) => string;
    uploadDocuments: (policyCountry: Country) => string;
    changeDocumentDelivery: (policyCountry: Country) => string;
    editCoverages: (policyCountry: Country) => string;
  };
  account: {
    changeAddress: string;
    login: string;
    logout: (origin: string, logoutId: string) => string;
    editPreferences: (contactId: string) => string;
    wallet: string;
  };
  corporate: {
    accessibility: string;
    disclosures: string;
    privacyPolicy: string;
    termsOfUse: string;
    aboutUs: string;
    agents: string;
    contactUs: string;
    faq: string;
    careers: string;
    marketplace: string;
    country: {
      usa: string;
      canada: string;
      uk: string;
    };
  };
  social: {
    facebook: string;
    instagram: string;
    linkedIn: string;
    youTube: string;
    twitter: string;
  };
  stateFarm: {
    claim: string;
  };
};

type EnvironmentMap = { [key in Environment]: string };

export const onPremMap: EnvironmentMap = {
  production: "",
  preprod: "mo",
  ci: "qa",
  sandbox: "dev",
  localhost: "localhost-on-prem",
};

export const cloudMap: EnvironmentMap = {
  production: "",
  preprod: "preprod",
  ci: "ci",
  sandbox: "sandbox",
  localhost: "localhost-cloud",
};
