import { createContext, ReactNode, useState } from "react";
import { createWrappedContext } from "../../hooks/createWrappedContext";

type DriversClubContext = {
  /** Determines if customer is a HDC member. */
  isHdcMember: boolean | undefined;

  /** Set isHdcMember. */
  setIsHdcMember: (isHdcMember: boolean) => void;
};

const Context = createContext<DriversClubContext | null>(null);
const useWrappedContext = createWrappedContext<DriversClubContext | null>({
  context: Context,
  providerName: "DriversClubContextProvider",
});

type DriversClubContextProviderProps = {
  children: ReactNode;
};

export const DriversClubContextProvider = ({
  children,
}: DriversClubContextProviderProps) => {
  // Defaults to undefined until hdc call is returned.
  const [isHdcMember, setIsHdcMember] = useState<boolean>(false);

  const value = { isHdcMember, setIsHdcMember };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/** Hook to get the setIsHdcMember and isHdcMember from app context */
export const useIsHdcMember = () => useWrappedContext("useIsHdcMember");
