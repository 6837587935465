import { Environment } from "shared";

const mapEnvironments = {
  localhost: "localhost",
  sandbox: "sandbox",
  ci: "ci",
  preprod: "preprod",
  hagerty: "production",
} as const;

type EnvironmentKey = keyof typeof mapEnvironments;

export const getEnvironment = (hostname: string): Environment => {
  // We're in prod if the second segment is "hagerty".
  // ex: "account.hagerty.com" or "account.hagerty.ca" — YES
  // ex: "localhost" or "account.sandbox.hagerty.com" or "account.ci.hagerty.ca" — NO
  // environmentKey is one of these values: hagerty, preprod, ci, sandbox, localhost, or some other value
  const [, environmentKey = "localhost"] = hostname.split(".");

  const environment: Environment =
    mapEnvironments[environmentKey as EnvironmentKey];

  // if environmentKey not found then throw
  if (!environment) throw new Error(`unknown environment: ${environmentKey}`);

  // return one of these values: production, preprod, ci, sandbox, or localhost
  return environment;
};
