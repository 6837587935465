import { phoneNumbers } from "shared";
import { Dialog } from "../Dialog";
import { Link } from "../Link";

type RoadsideHelpDialogProps = {
  onDismiss: () => void;
};

export const RoadsideHelpDialog = ({ onDismiss }: RoadsideHelpDialogProps) => {
  return (
    <Dialog title="Roadside Help" size="medium" onDismiss={onDismiss} role="dialog">
      <p>
        Call{" "}
        <Link
          tagAction={{ actionName: "roadside_phone", actionType: "link" }}
          phoneNumber={phoneNumbers.roadside}
        />{" "}
        for roadside assistance.
      </p>
    </Dialog>
  );
};
