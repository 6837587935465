import { Alert } from "../Alert";
import { formatPhoneNumber } from "shared";

import styles from "./ContactBroker.module.scss";
import { Broker, Partner } from "@hagerty/self-service-components/api/types";
import { Link } from "../Link";

type BrokerProps = {
  broker: Broker;
  partner?: Partner;
};

/** Renders a link to contact broker for policies with State Farm partner */
export const ContactBroker = ({ broker, partner }: BrokerProps) => {
  const { name, phone, email, phoneExtension } = broker;
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <Alert
      className={styles.root}
      level="info"
      message={
        <div className={styles.message}>
          <span className={styles.boldMessage}>
            {partner === "state-farm"
              ? "Need to modify your policy?"
              : "Need help?"}
          </span>
          {` Contact your agent ${name}`}
          {(phone || email) && " at "}
          <Link className={styles.link} phoneNumber={phone}>
            {formattedPhone}
          </Link>
          {email && (
            <>
              {phone && phoneExtension && ` Ext. ${phoneExtension}`}
              {phone && " or "}
              <Link className={styles.link} href={`mailto:${email}`}>
                {email}
              </Link>
            </>
          )}
          .
        </div>
      }
    />
  );
};
