import { App } from "./app";
import { AppError } from "./app-error";
import { Environment, TLD, URLs } from "shared";
import { parseDataIsland } from "./utils/parseDataIsland";
import * as Sentry from "@sentry/react";

type SuccessOrErrorComponentProps = {
  tld: TLD;
  urls: URLs;
  environment: Environment;
  accountDataElement: HTMLElement | null;
};

const { origin } = window.location;

export const SuccessOrErrorComponent = ({
  tld,
  urls,
  environment,
  accountDataElement,
}: SuccessOrErrorComponentProps) => {
  const dataIsland = parseDataIsland(accountDataElement);
  const { logoutId } = dataIsland;
  const logoutUrl = urls.account.logout(origin, logoutId);

  const error =
    dataIsland.status === "error" ? dataIsland.error : "An error has occurred";
  const appError = <AppError urls={urls} error={error} logoutUrl={logoutUrl} />;

  return dataIsland.status === "success" ? (
    <Sentry.ErrorBoundary fallback={appError}>
      <App
        urls={urls}
        tld={tld}
        environment={environment}
        customer={dataIsland.customer}
        contactId={dataIsland.contactId}
        featureFlags={dataIsland.featureFlags}
        isStateFarmPartitioned={dataIsland.isStateFarmPartitioned}
        logoutId={logoutId}
        emailHash={dataIsland.emailHash}
        isSso={dataIsland.isSso}
      />
    </Sentry.ErrorBoundary>
  ) : (
    appError
  );
};
