import { Policy, Vehicle } from "@hagerty/self-service-components/api/types";
import { getVehicleName } from "../../../utils/vehicleUtils";
import { Dialog } from "../../Dialog";
import { Button } from "@hagerty/self-service-components";
import style from "./RemoveVehicleDialog.module.scss";
import { useUrls } from "../../../app/Providers/AppContext";
import { TagAction } from "../../../types/Tag.types";
import { mt } from "../../../utils/tracking";
import { useEffect, useState } from "react";
import { useInsuranceContext } from "../InsuranceContext";

const confirmRemoveVehicleTagAction: TagAction = {
  actionName: "Yes, remove it",
  actionType: "button",
};

type RemoveVehicleDialogProps = {
  vehicle: Vehicle;
  policy: Policy;
  onClose: () => void;
};

export const RemoveVehicleDialog = ({
  vehicle,
  policy,
  onClose,
}: RemoveVehicleDialogProps) => {
  const [redirectRemoveVehicle, setRedirectRemoveVehicle] = useState(false);
  const urls = useUrls();
  const vehicleName = getVehicleName(vehicle);
  const { hasTooManyVehiclesOnSinglePolicy } = useInsuranceContext();
  const { policyNumber, country } = policy;

  useEffect(() => mt.trackPopup("Remove"), []);

  const closeDialog = () => {
    mt.trackPopupClose();
    onClose();
  };

  const onClickRemove = () => {
    mt.trackAction(confirmRemoveVehicleTagAction);
    setRedirectRemoveVehicle(true);
  };

  return (
    <Dialog label="Confirm remove vehicle" role="dialog" onDismiss={closeDialog} >
      <div className={`${style.dialogHeading} modal__section-title`}>
        Are you sure you want to remove this vehicle from your policy?
      </div>
      <div className={style.actionWrapper}>
        <Button
          color="primary"
          size="s"
          aria-label={`Remove ${vehicleName} from policy ${policyNumber}`}
          as="a"
          isLoading={redirectRemoveVehicle}
          href={urls.insurance.removeVehicle(
            policyNumber,
            vehicle.policyItemNumber.toString(),
            country,
            hasTooManyVehiclesOnSinglePolicy
          )}
          onClick={onClickRemove}
        >
          Yes, remove it
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          size="s"
          onClick={closeDialog}
          aria-label="Close remove vehicle dialog"
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};
