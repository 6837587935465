import { lazy, StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import "focus-visible";
import "../src/theme/globals.scss";
import * as Sentry from "@sentry/react";
import { getEnvironment } from "./utils/getEnvironment";
import { getTld } from "./utils/urlUtils";
import { getAppMetadata } from "./utils/getAppMetadata";
import { getUrls } from "shared";
import { SuccessOrErrorComponent } from "./SuccessOrErrorComponent";

// Lazy load dev tools so they're not part of the main prod bundle.
// This way they're not requested in production.
const AppDevTools = lazy(
  () =>
    import(/* webpackChunkName: "devtools" */ "./ui/AppDevTools/AppDevTools")
);
const tld = getTld(window.location.hostname);
const environment = getEnvironment(window.location.hostname);
const urls = getUrls(environment, tld);
if (environment !== "localhost") {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const appName = process.env.REACT_APP_NAME!;
  const { version: appVersion } = getAppMetadata(environment);

  Sentry.init({
    dsn: "https://f36be8c3ed6444c893873ddb43cb8117@o446007.ingest.sentry.io/5692499",
    tracesSampleRate: 1.0,
    release: `${appName}@${appVersion}`,
    environment,
  });
}

const accountDataElement = document.getElementById("customer-data");
const shouldRenderDevTools = process.env.REACT_APP_SHOW_DEV_TOOLS === "Y";
const isDataIslandPresent = !!accountDataElement;

const element = (
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<h1>An unhandled error occurred</h1>}>
      {shouldRenderDevTools && !isDataIslandPresent ? (
        <Suspense fallback="Loading with dev tools...">
          <AppDevTools urls={urls} />
        </Suspense>
      ) : (
        <SuccessOrErrorComponent
          tld={tld}
          urls={urls}
          environment={environment}
          accountDataElement={accountDataElement}
        />
      )}
    </Sentry.ErrorBoundary>
  </StrictMode>
);

ReactDOM.render(element, document.getElementById("root"));
