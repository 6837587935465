import * as Sentry from "@sentry/react";

/**
 * Download pdf to device via @param url
 * @returns whether the document was downloaded
 */
export async function downloadDocumentUrl(
  url: string,
  documentName: string
): Promise<boolean> {
  try {
    const fetchResponse = await fetch(url);
    const blob = await fetchResponse.blob();

    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.target = "_blank";
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    link.remove();

    return true;
  } catch (err) {
    Sentry.captureException(err, { extra: { url } });
    return false;
  }
}
