import styles from "./SectionHeader.module.scss";
import { MutableRefObject } from "react";

type SectionHeaderProps = {
  children?: React.ReactNode;
  heading: string;
  headerRef: MutableRefObject<HTMLHeadingElement | null>;
  ariaLabel?: string;
  id: string;
};

export function SectionHeader({
  children,
  heading,
  headerRef,
  ariaLabel,
  id,
}: SectionHeaderProps) {
  return (
    /* Setting tabindex to -1 since the heading is focused programmatically when an anchor link in the SubMenu is clicked. */
    <div className={styles.header} ref={headerRef} id={id} tabIndex={-1}>
      <h2 className={styles.headingText} aria-label={ariaLabel}>
        {heading}
      </h2>
      {children}
    </div>
  );
}
