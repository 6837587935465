import { genericErrorMessage } from "shared";
import { ButtonRow } from "../ButtonRow";

type ErrorRowProps = {
  resetErrorBoundary: () => void;
};

/** Used by `<ErrorBoundary/>` as a `FallbackComponent` */
export const ErrorRow = ({ resetErrorBoundary }: ErrorRowProps) => {
  return (
    <ButtonRow label={genericErrorMessage} onClick={resetErrorBoundary}>
      Try again
    </ButtonRow>
  );
};
