import { createUseFetch } from "fetch-suspense";
import { getApiVersion } from "../utils/getApiVersion";

let useFetch = createUseFetch();

export const clearSuspenseCache = () => {
  useFetch = createUseFetch();
};

export const useFetchApi = <T,>(resource: string, apiVersion = 1) => {
  const uri = `${window.location.origin}/api/${resource}`;
  const res = useFetch(
    uri,
    {
      headers: {
        accept: `application/vnd.hagerty.account-profile+json; version=${apiVersion}`,
      },
    },
    { lifespan: 1, metadata: true }
  );
  if (!res.ok) throw new Error(`Fetch error status ${res.status}`);

  const contentType = res.headers.get("content-type");
  const version = getApiVersion(contentType);

  if (version === 1)
    return {
      version,
      data: res.response as T,
    };

  // Because the response was returned with a content type other than application/json,
  // it's not automatically parsed by useFetch
  const data = JSON.parse(res.response as string) as T;
  return { version, data };
};
