import { Partner } from "@hagerty/self-service-components/api/types";
import style from "./Section.module.scss";

type SectionProps = {
  /** Aria-label that describes the section, as suggested here: https://www.w3.org/WAI/GL/wiki/Using_HTML5_section_element */
  ariaLabel: string;
  children: React.ReactNode;
  partner?: Partner;
};

export function Section({ children, ariaLabel, partner }: SectionProps) {
  return (
    <section
      role="contentinfo"
      aria-label={ariaLabel}
      className={`login-data-table ${style.section}`}
      data-partner={partner}
    >
      {children}
    </section>
  );
}
