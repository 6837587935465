import { createContext, useContext, ReactNode, useState } from "react";

const sectionHashes = ["billing", "insurance", "hdc", "settings"] as const;

export type SectionHash = (typeof sectionHashes)[number];

type ActiveSectionContext = {
  /** Stores which section is active. */
  activeSection: SectionHash | undefined;

  /** Set active section in app state. */
  setActiveSection: (section: SectionHash) => void;
};

const Context = createContext<ActiveSectionContext | null>(null);

type ActiveSectionContextProviderProps = {
  children: ReactNode;
};

/** Provider for Active Section context */
export const ActiveSectionContextProvider = ({
  children,
}: ActiveSectionContextProviderProps) => {
  const [activeSection, setActiveSection] = useState<SectionHash>("billing");

  const value: ActiveSectionContext = {
    activeSection,
    setActiveSection: (hash: SectionHash) => {
      if (hash !== activeSection) {
        setActiveSection(hash);
      }
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/** Hook to consume and set activeSection via context */
export function useActiveSectionContext() {
  const context = useContext(Context);
  if (context) return context;
  throw new Error(
    "useActiveSectionContext must be wrapped with <ActiveSectionContextProvider />"
  );
}
