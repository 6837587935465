import React, { ForwardedRef } from "react";

export interface FormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
}

export const FormInput = React.forwardRef(
  (
    { name, type = "text", ...rest }: FormInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className="form-element__control">
        <input ref={ref} type={type} className="text-input" {...rest} />
      </div>
    );
  }
);
