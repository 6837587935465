import React, { ReactNode, ForwardedRef } from "react";
import styles from "./Alert.module.scss";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "../../svg/close.svg";
import { ReactComponent as DownIcon } from "../../svg/down.svg";
import { AlertIcon } from "./AlertIcon";

export const alertLevels = [
  "default",
  "info",
  "warning",
  "success",
  "danger",
] as const;

export type AlertLevel = typeof alertLevels[number];

type AlertProps = {
  /** Optional aria-label to apply to the root element */
  ariaLabel?: string;

  /** Optional classname to apply to the root element */
  className?: string;

  /** Close button Aria-label */
  closeButtonAriaLabel?: string;

  /** Optional icon to display on the left-hand side which corresponds to `alertLevel`. */
  showIcon?: boolean;

  /** Optional title to display in bold at the start of the alert */
  title?: React.ReactNode;

  /** Message to display in the alert body */
  message?: ReactNode;

  /** Slot for displaying a call to action on the right-hand side after the content */
  actionSlot?: ReactNode;

  /** Optional function to call when clicked */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /** Pass a function to call on close. When a function is provided, the close button displays. */
  onClose?: () => void;

  /** Alert level */
  level: AlertLevel;

  /** Root element to render */
  as?: "button" | "div";
};

export const Alert = React.forwardRef(
  (
    {
      title,
      message,
      level,
      actionSlot,
      onClose,
      showIcon = false,
      closeButtonAriaLabel = "Close alert",
      className,
      ariaLabel,
      onClick,
      as: rootElement = "div",
    }: AlertProps,
    alertRef: ForwardedRef<any>
  ) => {
    const rootProps = {
      "aria-label": ariaLabel,
      ref: alertRef,
      className: clsx(
        "alert",
        `alert_${level}`,
        { alert_dismissible: onClose },
        { alert_collapsible: rootElement === "button" },
        styles.container,
        className
      ),
      role: "alert",
    };

    function renderChildren() {
      return (
        <>
          <div className={styles.alertLeft}>
            {showIcon && <AlertIcon level={level} />}
            <div className="alert__container">
              {title && <span className="alert__header">{title}</span>}
              <span className="alert__message">{message}</span>
            </div>
          </div>
          <div>
            <div className="alert__actionSlot">{actionSlot}</div>
            {onClose && (
              <div className="alert__actions">
                <button
                  type="button"
                  className="alert_close"
                  aria-label={closeButtonAriaLabel}
                  onClick={onClose}
                >
                  <CloseIcon className={styles.closeIcon} />
                </button>
              </div>
            )}
          </div>
        </>
      );
    }

    return rootElement === "div" ? (
      <div {...rootProps}>{renderChildren()}</div>
    ) : (
      <button {...rootProps} onClick={onClick}>
        {renderChildren()}
        <div className="alert__actions">
          <DownIcon className="icon icon_toggle" aria-hidden="true" />
        </div>
      </button>
    );
  }
);
