export const formatPhoneNumber = (phone: string | null = ""): string => {
  if (!phone) {
    return "";
  }
  const phoneRegExp = /^(\d\d\d)(\d\d\d)(\d\d\d\d)$/;
  const arr = phoneRegExp.exec(phone);
  if (!arr) {
    return phone; // format "as-is"
  }
  const [, area, exchange, local] = arr;
  return `(${area}) ${exchange}-${local}`;
};
