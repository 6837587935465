import { useState, useLayoutEffect } from "react";

/** Returns viewport width. Updates upon window resize.
 * @returns current value for window.innerWidth, stored in state so it triggers a React re-render
 */
export function useViewportWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return width;
}
