import { ReactNode } from "react";
import { DialogOverlay as ReachDialogOverlay } from "@reach/dialog";
import styles from "./DialogContent.module.scss";

export type DialogSize = "small" | "medium" | "full-screen";

type DialogOverlayProps = {
  size?: DialogSize;
  onDismiss?: () => void;
  children: ReactNode;
};

export const DialogOverlay = ({
  size = "small",
  onDismiss,
  children,
}: DialogOverlayProps) => {
  return (
    <ReachDialogOverlay
      className={`modal modal__centered modal_${size} ${styles.modal}`}
      isOpen={true}
      onDismiss={onDismiss}
    >
      {children}
    </ReachDialogOverlay>
  );
};
