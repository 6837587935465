import { Breadcrumb } from "@hagerty/self-service-components";
import { Link } from "../Link";
import { routes } from "../../routing/routes";
import styles from "./Layout.module.scss";
import { ReactNode } from "react";

export type LayoutProps = {
  children?: ReactNode;
  title?: string;
  subtitle?: string;
  hasBreadcrumb?: boolean;
};

export const Layout = ({
  title,
  subtitle,
  children,
  hasBreadcrumb = true,
}: LayoutProps) => {
  return (
    <div className="container container_center">
      <div className={styles.column}>
        {hasBreadcrumb && (
          <Breadcrumb>
            <Link href={routes.main}>Account home</Link>
          </Breadcrumb>
        )}
        {title && <h2 className={styles.title}>{title}</h2>}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        {children}
      </div>
    </div>
  );
};
