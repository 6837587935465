export const getApiVersion = (contentType: string | null): number => {
  if (contentType === null) throw new Error("missing content type");

  const [type, ...tokens] = contentType.split(";").map((str) => str.trim());

  if (type === "application/json") return 1;
  if (type !== "application/vnd.hagerty.account-profile+json") {
    throw new Error("unknown content type");
  }

  const versionToken = tokens.find((item) => item.startsWith("version"));
  if (!versionToken) throw new Error("missing version token");
  const [, versionStr] = versionToken.split("=").map((str) => str.trim());
  const version = parseInt(versionStr);
  return version;
};
