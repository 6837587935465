import sentUrl from "../../svg/sent.svg";
import { Pane } from "./Pane";

type SuccessPaneProps = {
  active: boolean;
  policyName: string;
  email: string;
};

export const SuccessPane = ({
  active,
  policyName,
  email,
}: SuccessPaneProps) => (
  <Pane active={active}>
    <img aria-hidden="true" src={sentUrl} alt="email sent" />
    <h3>
      We sent a link to sign the {policyName} policy documents to{" "}
      <strong>{email}</strong>.
    </h3>
    <p>
      Please click on the link in the email to sign the documents and finalize
      your policy changes.
    </p>
  </Pane>
);
