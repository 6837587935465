import { ReactComponent as Logo } from "./hagerty.svg";
import styles from "./HagertyLogo.module.scss";
import cx from "clsx";

type HagertyLogoProps = {
  className?: string;
};

/** Hagerty Logo SVG */
export function HagertyLogo({ className }: HagertyLogoProps) {
  return <Logo className={cx(styles.logo, className)} />;
}
