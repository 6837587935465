import { ReactComponent as Car } from "./img/car.svg";
import { ReactComponent as Road } from "./img/road.svg";

type NoInsuranceWithoutConnectPolicyProps = {
  titleText: string;
  subTitleText: string;
  styles: Record<string, string>;
};

export const NoInsuranceWithoutConnectPolicy = ({
  titleText,
  subTitleText,
  styles,
}: NoInsuranceWithoutConnectPolicyProps) => {
  return (
    <>
      <div className={styles.titleArea}>
        <h2>{titleText}</h2>
        <p>{subTitleText}</p>
      </div>

      <Road className={styles.roadNoData} />
      <Car className={styles.carNoData} />

      <div className={styles.footer} />
    </>
  );
};
