import { Country } from "@hagerty/self-service-components/api/types";
import { useCallback, useState } from "react";
import { useUrls } from "../../app/Providers/AppContext";
import { downloadDocumentUrl } from "../../utils/downloadDocumentUrl";

export type IdCardUrl = {
  url: string;
};

export const useDownloadIdCard = (policyNumber: string, country: Country) => {
  const urls = useUrls();
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState<Error>();

  const download = useCallback(async () => {
    setIsDownloading(true);
    const response = await fetch(
      urls.insurance.getCardUrl(window.location.origin, country, policyNumber),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (!response || !response.ok) {
      setError(new Error("Couldn't fetch id cards url"));
      setIsDownloading(false);
      return;
    }

    const url = await response.text();

    const documentName = `${policyNumber}_${country}_id_card.pdf`;
    const didDownload = await downloadDocumentUrl(url, documentName);

    setIsDownloading(false);
    setError(
      didDownload ? undefined : new Error("Unable to launch id cards url")
    );
  }, [policyNumber, country]);

  return { download, isDownloading, error };
};
