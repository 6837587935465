import { Alert } from "../Alert";
import { AlertGroup } from "../AlertGroup/AlertGroup";
import { Action } from "./Action";
import styles from "./RenderAlerts.module.scss";
import { RenderAlertsProps } from "./RenderAlertsProps";

export const RenderAlerts = ({ alerts }: RenderAlertsProps) => {
  if (alerts.length === 0) return null;

  if (alerts.length === 1) {
    const [{ title, message, level, actionConfig }] = alerts;

    return (
      <Alert
        level={level}
        className={styles.root}
        title={title}
        showIcon
        message={<div>{message}</div>}
        actionSlot={<Action actionConfig={actionConfig} actionStyle="button" />}
      />
    );
  }

  // Rule: If at least one alertLevel is "danger", the AlertGroup should have an alertLevel of danger.
  const level = alerts.some(({ level }) => level === "danger")
    ? "danger"
    : "warning";

  return (
    <AlertGroup
      className={styles.root}
      level={level}
      ariaLabel="Alerts"
      showIcon
      alerts={alerts}
    />
  );
};
