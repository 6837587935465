import { HagertyLogo } from "../HagertyLogo";
import styles from "./DriversClubLogo.module.scss";
import cx from "clsx";

type DriversClubLogoProps = {
  className?: string;
};

export const DriversClubLogo = ({ className }: DriversClubLogoProps) => (
  <div className={cx(styles.container, className)}>
    <HagertyLogo className={styles.logo} />
    <div className={styles.verticalBar} />
    <div className={styles.wordMark}>Drivers Club</div>
  </div>
);
