import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { formatPhoneNumber } from "shared";
import styles from "./PolicyInfo.module.scss";
import { ListCard } from "@hagerty/self-service-components";
import { Link } from "../../Link";

type PolicyInfoBrokerProps = {
  policy: CorePolicy;
};

export const PolicyInfoBroker = ({ policy }: PolicyInfoBrokerProps) => {
  const { broker } = policy;
  if (!broker) {
    return null;
  }

  const { name, phone, phoneExtension } = broker;
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <ListCard.Row>
      <div className={styles.column}>
        <div className={styles.name}>Agent</div>
        <div className={styles.details}>
          {name}
          {phone && (
            <div>
              <Link className={styles.link} phoneNumber={phone}>
                {formattedPhone}
              </Link>
              {phoneExtension && <span>{`, ext. ${phoneExtension}`}</span>}
            </div>
          )}
        </div>
      </div>
    </ListCard.Row>
  );
};
