import {
  Dialog,
  DialogButtonGroup,
  DialogButton,
  DialogHeading,
} from "../Dialog";

type UnsavedChangesDialogProps = {
  onClose: () => void;
  onCancel: () => void;
};

export const UnsavedChangesDialog = ({
  onClose,
  onCancel,
}: UnsavedChangesDialogProps) => {
  return (
    <Dialog
      label="Unsaved changes"
      role="alert"
      showCloseButton={"no"}
      onDismiss={onCancel}
      footer={
        <DialogButtonGroup>
          <DialogButton onClick={onClose} variant="outline">
            Yes, leave
          </DialogButton>
          <DialogButton autoFocus onClick={onCancel}>
            Keep editing
          </DialogButton>
        </DialogButtonGroup>
      }
    >
      <DialogHeading>Leave without saving?</DialogHeading>
      <p>Your changes will be lost.</p>
    </Dialog>
  );
};
