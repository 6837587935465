import { ReactComponent as CloseIcon } from "../../svg/close.svg";
import styles from "./DialogCloseButton.module.scss";

const closeThisDialog = "Close this dialog";

export interface DialogCloseButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  ariaLabel?: string;
}

export const DialogCloseButton = ({
  onClick,
  disabled,
  ariaLabel = closeThisDialog,
  ...rest
}: DialogCloseButtonProps) => {
  return (
    <button
      className={`button button-close button-close_round button-close_large modal__close-button ${styles.closeButton}`}
      type="button"
      title={closeThisDialog}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <CloseIcon focusable="false" aria-hidden="true" />
    </button>
  );
};
