import { useCallback, useState } from "react";
import { useUrls } from "../../app/Providers/AppContext";
import { PolicySource } from "shared";
import { DocumentTag } from "@hagerty/customer-insurance-gateway-client";

export type DocumentUrl = {
  url: string;
};

export const useDownloadDocument = (
  policyNumber: string,
  source: PolicySource,
  document: DocumentTag
) => {
  const urls = useUrls();
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState<Error>();

  const download = useCallback(async () => {
    setIsDownloading(true);
    const response = await fetch(
      urls.insurance.getDocumentUrl(
        window.location.origin,
        source,
        document,
        policyNumber
      ),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (!response || !response.ok) {
      setError(new Error("Couldn't fetch document url"));
      setIsDownloading(false);
      return;
    }

    const url = await response.text();

    // TODO: download document instead of opening in new window
    // We will need to update CSP to allow download from MCMM asset url
    //
    // const documentName = `${policyNumber}_${document}.pdf`;
    // const didDownload = await downloadDocumentUrl(url, documentName);
    // setError(
    //   didDownload ? undefined : new Error(`Unable to download ${document}`)
    // );

    window.open(url, "_blank")?.focus();
    setIsDownloading(false);
    setError(undefined);
  }, [policyNumber]);

  return { download, isDownloading, error };
};
