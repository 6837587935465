import clsx from "clsx";
import { useState } from "react";
import { Button } from "@hagerty/self-service-components";
import { mt } from "../../utils/tracking";
import { Link } from "../Link";
import { ActionProps } from "./Action.props";
import styles from "./Action.module.scss";

export const Action = ({
  className,
  actionConfig,
  actionStyle,
}: ActionProps) => {
  const [redirecting, setRedirecting] = useState(false);

  if (!actionConfig) return null;

  const { ariaLabel, text, tagAction, showLoader } = actionConfig;

  const href = "href" in actionConfig ? actionConfig.href : undefined;
  const onClick = () => {
    if (showLoader) {
      setRedirecting(true);
    }

    if (tagAction) {
      mt.trackAction(tagAction);
    }

    if (actionConfig.onClick) {
      actionConfig.onClick();
    }
  };

  if (actionStyle == "link") {
    if (href) {
      return (
        <Link
          ariaLabel={ariaLabel}
          className={className}
          onClick={onClick}
          href={href}
          isLoading={redirecting}
        >
          {text}
        </Link>
      );
    }

    return (
      <Button
        as="a"
        aria-label={ariaLabel}
        variant="text"
        className={className}
        onClick={onClick}
        isLoading={redirecting}
      >
        {text}
      </Button>
    );
  }

  return (
    <Button
      aria-label={ariaLabel}
      as="a"
      color="dark"
      size="xs"
      className={clsx(className, { [styles.button]: !redirecting })}
      href={href}
      onClick={onClick}
      isLoading={redirecting}
    >
      {text}
    </Button>
  );
};
