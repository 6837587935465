import { SignDocumentsDialog } from "../SignDocumentsDialog";
import { useAlerts } from "./useAlerts";
import { RenderAlerts } from "./RenderAlerts";
import { useESign } from "./useESign";

export function Alerts() {
  const { shouldShowDialog, policyName, status, email, send, resend, dismiss } =
    useESign();
  const alerts = useAlerts(send);

  return (
    <>
      <RenderAlerts alerts={alerts} />

      {shouldShowDialog && (
        <SignDocumentsDialog
          policyName={policyName}
          status={status}
          email={email}
          resend={resend}
          onDismiss={dismiss}
        />
      )}
    </>
  );
}
