import { Customer } from "shared";
import { ActiveSectionContextProvider } from "../../app/Providers/ActiveSectionContext";
import { AccountSettings } from "../AccountSettings";
import { BillingPayment } from "../BillingPayment";
import { DriversClub } from "../DriversClub";
import { Alerts } from "../Alerts";
import { Region } from "../Region";
import { SubMenu } from "../Submenu/SubMenu";
import { Welcome } from "../Welcome";
import { useIsHdcMember } from "../../app/Providers/DriversClubContext";
import { useIsPolicyHolder } from "../../app/Providers/PolicyContext";
import {
  useIsStateFarmPartitioned,
  useIsSso,
} from "../../app/Providers/AppContext";
import { Insurance } from "../Insurance";

type AccountProfileProps = {
  customer: Customer;
};

/** Renders the Account Profile app (i.e. the sub menu items and all four regions) */
export const AccountProfile = ({ customer }: AccountProfileProps) => {
  const isSso = useIsSso();
  const hasStateFarmPartition = useIsStateFarmPartitioned();
  const { isHdcMember } = useIsHdcMember();
  const { isPolicyHolder } = useIsPolicyHolder();
  const isStateFarmSso = hasStateFarmPartition && isSso;
  const isProductPurchased = isPolicyHolder || isHdcMember;
  const showBillingAndHdc = isHdcMember || !isStateFarmSso;
  const showBilling = showBillingAndHdc && isProductPurchased;

  return (
    <div className="container container_center">
      <div className="grid gutters_direct grid-wrap grid_align-center">
        <ActiveSectionContextProvider>
          <SubMenu
            shouldShowHdc={showBillingAndHdc}
            shouldShowBilling={showBilling}
          />

          <main
            id="main"
            className="col xs-size_1-of-1 lg-size_9-of-12 xl-size_8-of-12"
          >
            <Welcome>{customer.firstName}</Welcome>

            <Alerts />

            <div className="content">
              <>
                {/* Display new BillingPayment component and pass false to the InsuranceWithBilling component */}
                {showBilling && (
                  <>
                    <Region
                      title="Upcoming Bills"
                      contentSlot={<BillingPayment />}
                      hash="billing"
                    />
                  </>
                )}
              </>
              <Insurance />
              <DriversClub shouldShowHdc={showBillingAndHdc} />

              <Region
                title="Account Settings"
                contentSlot={<AccountSettings initialCustomer={customer} />}
                hash="settings"
              />
            </div>
          </main>
        </ActiveSectionContextProvider>
      </div>
    </div>
  );
};
