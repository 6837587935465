import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { TagAction } from "../../../types/Tag.types";
import { Card, Popover } from "@hagerty/self-service-components";
import { useUrls } from "../../../app/Providers/AppContext";
import { Tile } from "../../Tile";
import { Claims } from "../../../svg";
import styles from "./PolicyTiles.module.scss";
import { ReactComponent as DownChevron } from "../../../svg/chevron-down.svg";
import { useRef, useState } from "react";
import { getFileClaimUrl } from "../../../utils/urlUtils";
import { Link } from "../../Link";

type ClaimsTileProps = {
  policy: CorePolicy;
};

export const ClaimsTile = ({ policy }: ClaimsTileProps) => {
  const [redirect, setRedirect] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const { country, policyNumber } = policy;
  const urls = useUrls();

  const fileClaimTagAction: TagAction = {
    actionName: "File a claim",
    actionType: "link",
  };
  const claimStatusTagAction: TagAction = {
    actionName: "Claim status",
    actionType: "link",
  };

  const fileClaimUrl = getFileClaimUrl(policy, urls);
  const claimStatusUrl = urls.insurance.fileClaim(policyNumber, country);

  const hidePopover = () => {
    setIsPopOverOpen(false);
    triggerRef?.current?.blur();
  };

  const onClickLink = () => {
    hidePopover();
    setRedirect(true);
  };

  return (
    <>
      <Tile
        icon={<img src={Claims} alt="Claims" aria-hidden="true" />}
        className={styles.tile}
        cta="Claims"
        onClick={() => setIsPopOverOpen(!isPopOverOpen)}
        ctaIcon={<DownChevron />}
        ref={triggerRef}
        isLoading={redirect}
      />
      <Popover
        triggerRef={triggerRef}
        onClose={() => setTimeout(hidePopover)}
        isOpen={isPopOverOpen}
        placement={"bottom start"}
        className={styles.popover}
        restoreFocus={false}
      >
        <Card className={styles.card}>
          <Link
            tagAction={fileClaimTagAction}
            onClick={onClickLink}
            href={fileClaimUrl}
          >
            File a claim
          </Link>
          {country === "USA" && (
            <Link
              tagAction={claimStatusTagAction}
              onClick={onClickLink}
              href={claimStatusUrl}
            >
              Claim status
            </Link>
          )}
        </Card>
      </Popover>
    </>
  );
};
