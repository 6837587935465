import { Spinner } from "../Spinner/Spinner";
import { Pane } from "./Pane";

type PendingPaneProps = {
  active: boolean;
  policyName: string;
};

export const PendingPane = ({ active, policyName }: PendingPaneProps) => (
  <Pane active={active}>
    <Spinner />
    <h3>We're sending a link to sign the {policyName} policy documents.</h3>
  </Pane>
);
