// Eventually benefits will come from the new API design, but for now, compute using a
// similar method that we used to use in the SPA.
import { Country } from "@hagerty/self-service-components/api/types";
import { Benefits } from ".";

type LevelToBenefitsMap = {
  [key in Country]: (Benefits | undefined)[];
};

const levelToBenefitsMap: LevelToBenefitsMap = {
  USA: [
    undefined,
    { distance: 20, coversAllVehicles: false },
    { distance: 100, coversAllVehicles: false },
    { distance: 150, coversAllVehicles: true },
    { distance: 150, coversAllVehicles: true },
    { distance: 100, coversAllVehicles: false },
  ],
  CANADA: [
    undefined,
    { distance: 30, coversAllVehicles: false },
    { distance: 80, coversAllVehicles: false },
    { distance: 200, coversAllVehicles: true },
    { distance: 200, coversAllVehicles: true },
  ],
};

export const getHdc1Dot5Benefits = (
  level: number,
  country: Country
): Benefits | undefined => {
  const benefits = levelToBenefitsMap[country][level];
  return benefits;
};

export const getIsVip = (level: number): boolean => level === 4;

// HACK: There are two types of vip 1.5 members that should see expiration dates.
// Members with 1 year membership and those with lifetime membership. For now we are checking if the
// expiration date is over 50 years from now to determine if expiration date should be displayed or hidden
// for vip 1.5 members.
function isOver50YearsInFuture(date: string) {
  return new Date(date) > new Date("2073/01/01");
}

export const getVipOneDotFiveExpirationDate = (
  expirationDate: string
): string | undefined =>
  isOver50YearsInFuture(expirationDate) ? undefined : expirationDate;
