import { ReactNode } from "react";
import { DialogContent, ShowCloseButton } from "./DialogContent";
import { DialogOverlay, DialogSize } from "./DialogOverlay";

type DialogProps = {
  className?: string;
  label?: string;
  children: ReactNode;
  title?: string;
  footer?: ReactNode;
  description?: string;
  size?: DialogSize;
  onDismiss?: () => void;
  showCloseButton?: ShowCloseButton;
  showBusy?: boolean;
  ariaBusy?: boolean;
  role?: string;
};

export const Dialog = ({
  className,
  title,
  footer,
  description,
  size,
  label,
  onDismiss,
  children,
  showCloseButton,
  showBusy = false,
  ariaBusy,
  role,
}: DialogProps) => {
  return (
    <DialogOverlay onDismiss={onDismiss} size={size}>
      <DialogContent
        role={role}
        ariaBusy={ariaBusy}
        label={label}
        title={title}
        className={className}
        footer={footer}
        onDismiss={onDismiss}
        showCloseButton={showCloseButton}
        showBusy={showBusy}
        description={description}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  );
};
