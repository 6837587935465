import styles from "./SubMenu.module.scss";
import { ReactComponent as HDC } from "./svg/hdc.svg";
import { ReactComponent as Payment } from "./svg/payment.svg";
import { ReactComponent as Insurance } from "./svg/insurance.svg";
import { ReactComponent as Settings } from "./svg/account-settings.svg";
import { useViewportWidth } from "../../hooks/useViewportWidth";
import { useActiveSectionContext } from "../../app/Providers/ActiveSectionContext";
import { SubMenuItem } from "./SubMenuItem";
import { mt } from "../../utils/tracking";
import { getSubMenuLinks } from "./getSubMenuLinks";
import { Link } from "./types/SubMenu.types";

type SubMenuProps = {
  shouldShowBilling?: boolean;
  shouldShowHdc?: boolean;
};

export const SubMenu = ({
  shouldShowHdc = true,
  shouldShowBilling = true,
}: SubMenuProps) => {
  const { activeSection } = useActiveSectionContext();
  const viewportWidth = useViewportWidth();
  const isMobile = viewportWidth < 768;

  // Title casing the sub menu items per product and UI design team as an exception to general hds rule of sentence casing.
  const allLinks: Link[] = [
    { hash: "billing", title: "Billing", icon: Payment },
    {
      hash: "insurance",
      title: "Insurance",
      icon: Insurance,
    },
    { hash: "hdc", title: "Drivers Club", icon: HDC },
    {
      hash: "settings",
      title: isMobile ? "Settings" : "Account Settings",
      icon: Settings,
    },
  ];

  const links = getSubMenuLinks(allLinks, shouldShowBilling, shouldShowHdc);

  return (
    <nav
      id="submenu"
      aria-label="Page quick links"
      className={`col lg-size_3-of-12 ${styles.sticky} ${styles.submenu}`}
    >
      <ul className={styles.submenu__nav}>
        {links.map(({ title, hash, icon }) => (
          <SubMenuItem
            key={hash}
            title={title}
            hash={hash}
            icon={icon}
            isActive={activeSection === hash}
            onClick={() => {
              mt.trackAction({ actionName: hash, actionType: "link" });
            }}
          />
        ))}
      </ul>
    </nav>
  );
};
