import { useContext } from "react";

type UseWrappedContext<T> = {
  context: React.Context<T>;
  providerName: string;
};

/** A factory function that returns a `useWrappedContext` hook that returns context or throws if component is not wrapped. */
export const createWrappedContext = <T>({
  context,
  providerName,
}: UseWrappedContext<T>): ((hookName: string) => Exclude<T, null>) => {
  const useWrappedContext = (hookName: string) => {
    const value = useContext(context);
    if (value === null) {
      throw new Error(`${hookName} must be wrapped with <${providerName}/>`);
    }
    return value as Exclude<T, null>;
  };
  return useWrappedContext;
};
