import { Country } from "@hagerty/self-service-components/api/types";
import { createContext, ReactNode } from "react";
import { Environment, FeatureFlags, TLD, URLs } from "shared";
import { createWrappedContext } from "../../hooks/createWrappedContext";

type AppContext = {
  /** Top Level Domain (TLD) either "com" or "ca" */
  tld: TLD;

  /** User's country */
  country: Country;

  /** User's email */
  email: string;

  /** The user's Salesforce ID. */
  contactId: string;

  /** The base environment string. */
  environment: Environment;

  /** Feature flags record. */
  featureFlags: FeatureFlags;

  /** GUID that assures only the user can log themselves out */
  logoutId: string;

  /** Object containing all the site's URLs, organized by category */
  urls: URLs;

  /** True if customer has a State Farm partition record */
  isStateFarmPartitioned: boolean;

  /** True if customer has logged in via SSO */
  isSso: boolean;
};

const Context = createContext<AppContext | null>(null);
const useWrappedContext = createWrappedContext<AppContext | null>({
  context: Context,
  providerName: "AppContextProvider",
});

type AppContextProviderProps = {
  tld: TLD;
  email: string;
  environment: Environment;
  country: Country;
  contactId: string;
  isStateFarmPartitioned: boolean;
  children: ReactNode;
  featureFlags: FeatureFlags;
  urls: URLs;
  logoutId: string;
  isSso: boolean;
};

/** Provider for global application context */
export const AppContextProvider = ({
  tld,
  email,
  environment,
  country,
  contactId,
  isStateFarmPartitioned,
  children,
  featureFlags,
  urls,
  logoutId,
  isSso,
}: AppContextProviderProps) => {
  const value = {
    tld,
    email,
    country,
    contactId,
    isStateFarmPartitioned,
    environment,
    featureFlags,
    urls,
    logoutId,
    isSso,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/** Hook to get the entire app context */
export const useAppContext = () => {
  const appContext = useWrappedContext("useAppContext");
  return appContext;
};

/** Hook to get the currency from app context */
export const useCountry = () => {
  const { country } = useWrappedContext("useCountry");
  return country;
};

/** Hook to get the contact id from app context */
export const useContactId = () => {
  const { contactId } = useWrappedContext("useContactId");
  return contactId;
};

/** Hook to get the environment from app context */
export const useEnvironment = () => {
  const { environment } = useWrappedContext("useEnvironment");
  return environment;
};

/** Hook to get the TLD from app context */
export const useTld = () => {
  const { tld } = useWrappedContext("useTld");
  return tld;
};

/** Hook to get the user's email from app context */
export const useEmail = () => {
  const { email } = useWrappedContext("useEmail");
  return email;
};

/** Hook to get a feature flag's value from app context */
export const useFeatureFlag = () => {
  const { featureFlags } = useWrappedContext("useFeatureFlag");
  return featureFlags;
};
/** Hook to get site's URLs from app context */
export const useUrls = () => {
  const { urls } = useWrappedContext("useUrls");
  return urls;
};

/** Hook to get the logoutId from app context */
export const useLogoutId = () => {
  const { logoutId } = useWrappedContext("useLogoutId");
  return logoutId;
};

/** Hook to get useIsStateFarmPartitioned from app context */
export const useIsStateFarmPartitioned = () => {
  const { isStateFarmPartitioned } = useWrappedContext(
    "useIsStateFarmPartitioned"
  );
  return isStateFarmPartitioned;
};

/** Hook to get useIsSso from app context */
export const useIsSso = () => {
  const { isSso } = useWrappedContext("useIsSso");
  return isSso;
};
