import { Tile } from "../Tile";
import {
  Magazine,
  MemberDiscount,
  RentalCoverage,
  RoadsideService,
} from "../../svg";
import styles from "./DriversClub.module.scss";
import { useDialog } from "../Dialog";
import { DriversClub1Dot0, DriversClub1Dot5 } from "shared";
import { useUrls } from "../../app/Providers/AppContext";
import { RoadsideHelpDialog } from "./RoadsideHelpDialog";
import { mt } from "../../utils/tracking";

const { origin } = window.location;

type DriversClubTilesProps = {
  hdc: DriversClub1Dot0 | DriversClub1Dot5;
};

export const DriversClubTiles = ({ hdc }: DriversClubTilesProps) => {
  const urls = useUrls();
  const roadSideDialog = useDialog();

  const openRoadsideDialog = () => {
    mt.trackPopup("roadside");
    roadSideDialog.open();
  };

  const closeRoadsideDialog = () => {
    mt.trackPopupClose();
    roadSideDialog.close();
  };

  const isHdcOneDotZero = hdc.hdcVersion === "1.0";

  return (
    <>
      <div className={styles.hdcTiles}>
        {hdc.benefits && (
          <Tile
            icon={
              <img
                src={RoadsideService}
                alt="Roadside Service"
                aria-hidden="true"
              />
            }
            className={styles.tile}
            cta="Get roadside help"
            tagAction={{ actionName: "Get roadside help", actionType: "link" }}
            onClick={openRoadsideDialog}
          />
        )}

        <Tile
          icon={
            <img
              src={RentalCoverage}
              alt="Rental coverage"
              aria-hidden="true"
            />
          }
          className={styles.tile}
          cta="Drivers Club ID"
          ariaLabel="Access Drivers Club ID"
          href={urls.hdc.viewMembershipCard(origin)}
          tagAction={{ actionName: "Drivers Club ID", actionType: "link" }}
          newTab
        />

        <Tile
          icon={<img src={Magazine} alt="Redeem benefits" aria-hidden="true" />}
          className={styles.tile}
          cta="Redeem benefits"
          href={urls.hdc.redeemBenefits}
          tagAction={{ actionName: "Redeem benefits", actionType: "link" }}
        />

        {!isHdcOneDotZero && (
          <Tile
            icon={
              <img src={MemberDiscount} alt="Manage HDC" aria-hidden="true" />
            }
            className={styles.tile}
            cta="Manage HDC"
            href={urls.hdc.upgradeOrCancel}
            tagAction={{
              actionName: "Manage HDC",
              actionType: "link",
            }}
          />
        )}
      </div>

      {roadSideDialog.isOpen && (
        <RoadsideHelpDialog onDismiss={closeRoadsideDialog} />
      )}
    </>
  );
};
