import { Country } from "@hagerty/self-service-components/api/types";

export const phoneNumbers = {
  auto: "1-877-922-9701",
  roadside: "1-888-310-8020",
  hdc: "1-888-216-2903",
  apex: "1-888-432-8217",
  insurancePaymentError: (country: Country): string =>
    country === "USA" ? "1-888-411-8638" : "1-888-216-2899",
};
