import { Button, Error } from "@hagerty/self-service-components";
import styles from "./NotFound.module.scss";
import { routes } from "../routing/routes";

export function NotFound() {
  return (
    <div className={styles.errorWrapper}>
      <Error title="Page not found">
        <Button as="a" href={routes.main}>
          Go to account home
        </Button>
      </Error>
    </div>
  );
}
