import { Layout } from "../../Layout/Layout";
import styles from "./LoaderWithSkeletons.module.scss";
import {
  ButtonSkeleton,
  PolicySectionSkeleton,
  TileSkeleton,
} from "@hagerty/self-service-components";

export const LoaderWithSkeletons = () => {
  return (
    <Layout hasBreadcrumb={false}>
      <div className={styles.smallTextSkeleton} />
      <h2 className={styles.title}>Policy</h2>
      <div className={styles.largeTextSkeleton} />
      <div className={styles.mobileSkeleton} />
      <div className={styles.actionsWrapper}>
        <TileSkeleton />
        <TileSkeleton />
        <TileSkeleton />
        <TileSkeleton />
      </div>
      <PolicySectionSkeleton className={styles.sectionSkeleton} />
      <PolicySectionSkeleton
        className={styles.sectionSkeleton}
        title="Vehicles"
        hasButton
      />
      <PolicySectionSkeleton
        className={styles.sectionSkeleton}
        title="Drivers"
      />
      <PolicySectionSkeleton
        className={styles.sectionSkeleton}
        title="Policy Coverages"
        hasButton
      />
      <ButtonSkeleton className={styles.buttonSkeleton} />
    </Layout>
  );
};
