import styles from "./AlertRow.module.scss";
import { Action } from "../Alerts/Action";
import { ActionConfig } from "../Alerts/ActionConfig.types";

type AlertRowProps = {
  /** Title to be displayed before the message */
  title: React.ReactNode;

  /** Optional message to display on the alert after the title. */
  message?: React.ReactNode;

  /** Displays an optional call to action on the right-hand side when populated.  */
  actionConfig?: ActionConfig;
};

export const AlertRow = ({ title, message, actionConfig }: AlertRowProps) => (
  <div className="alert__panel-item">
    <div className={`alert__container ${styles.container}`}>
      <span>
        {title} {message}
      </span>

      <Action
        className={styles.action}
        actionConfig={actionConfig}
        actionStyle="link"
      />
    </div>
  </div>
);
