import {
  SectionHash,
  useActiveSectionContext,
} from "../app/Providers/ActiveSectionContext";
import useEventListener from "@use-it/event-listener";

// Anytime a target's "top" is less than this value, it's considered active.
const targetTop = 150;

/**
 * Sets link as active based on scroll position.
 * @param elementRef Element whose position should be monitored to determine if it's active. The element is considered active if its top < targetTop (declared above).
 * @param hash Hash to set in URL when section is active.
 */
export function useActiveSection(elementRef: any, hash: SectionHash) {
  const { setActiveSection } = useActiveSectionContext();

  // TODO: Throttle this call for performance
  useEventListener("scroll", () => {
    const element = elementRef.current;
    if (!element) return;

    const { top } = element.getBoundingClientRect();
    if (top >= 0 && top < targetTop) {
      setActiveSection(hash);
    }
  });
}
