import React from "react";

export type ParagraphSizes = 1 | 2 | 3 | 4;

export type ParagraphProps = {
  /** Content to display inside paragraph. */
  children: React.ReactNode;

  /** Additional CSS class(es) to apply. */
  className?: string;

  /** Set to true to declare the paragraph as a lead. This applies a bolder style. */
  lead?: boolean;

  /** Specify the font size. Size 1 is largest. */
  size?: ParagraphSizes;
};

/** A reusable paragraph tag with optional sizes. */
export function P({ lead = false, size = 2, children }: ParagraphProps) {
  const className = lead ? `text-lead_${size - 1}` : `text_${size}`;
  return <p className={className}>{children}</p>;
}
