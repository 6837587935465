import {
  DialogContent,
  DialogOverlay,
  DialogButtonGroup,
  DialogButton,
} from "../Dialog";
import styles from "./AccountSettings.module.scss";

type PreferencesInfoDialogProps = {
  onCloseDialog: () => void;
};

export const PreferencesInfoDialog = ({
  onCloseDialog,
}: PreferencesInfoDialogProps) => {
  return (
    <DialogOverlay onDismiss={onCloseDialog}>
      <DialogContent
        role="dialog"
        title="Cannot update preferences in non-prod"
        showCloseButton={"no"}
        className={styles.accountSettings}
        onDismiss={onCloseDialog}
        footer={
          <DialogButtonGroup>
            <DialogButton aria-label="close dialog" onClick={onCloseDialog}>
              Close
            </DialogButton>
          </DialogButtonGroup>
        }
      >
        <p>
          Changing preferences in lower environments updates production data.
          This will be available when Salesforce Marketing Cloud is available in
          lower environents.
        </p>
      </DialogContent>
    </DialogOverlay>
  );
};
