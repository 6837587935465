import { useRef } from "react";
import { useActiveSection } from "../../hooks/useActiveSection";
import { Row } from "../Row";
import { Section } from "../Section";
import { SectionHeader } from "../SectionHeader";

export const InsuranceLoading = () => {
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  useActiveSection(headerRef, "insurance");
  return (
    <Section ariaLabel="Insurance">
      <SectionHeader
        heading={"Insurance"}
        id="insurance"
        headerRef={headerRef}
      />
      <Row isLoading />
    </Section>
  );
};
