import { useUrls } from "../../app/Providers/AppContext";
import { Link } from "../Link";
import styles from "./Disclaimer.module.scss";

export const Disclaimer = () => {
  const urls = useUrls();
  return (
    <div className={styles.disclaimer}>
      <p>
        If you have an insurance policy with Hagerty, you may also need to
        update your insurance address. Because premiums vary from one zip code
        to another, you'll have to do that separately.
      </p>
      <p>
        <Link newTab href={urls.account.changeAddress}>
          View your insurance address
        </Link>
      </p>
    </div>
  );
};
