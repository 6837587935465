import { formatDateISO } from "shared";

type DateRangeProps = {
  start: string;
  end?: string;
};

export const DateRange = ({ start, end }: DateRangeProps) => (
  <>
    {formatDateISO(start)}
    {end && ` - ${formatDateISO(end)}`}
  </>
);
