import { ReactNode } from "react";
import { Row } from "../Row";
import styles from "./ButtonRow.module.scss";

type ButtonRowProps = {
  label: string;
  onClick: () => void;
  children: ReactNode;
};

/** Renders a `<Row/>` with a button as its child */
export const ButtonRow = ({ label, onClick, children }: ButtonRowProps) => {
  return (
    <Row label={label}>
      <button className={styles.button} onClick={onClick}>
        {children}
      </button>
    </Row>
  );
};
