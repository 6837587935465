import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { TLD, URLs } from "shared";

export const getTld = (hostname: string): TLD => {
  const tld = hostname.split(".").pop();
  if (tld === "localhost") return "com";
  if (tld === "com" || tld === "ca") return tld;

  throw new Error(`unknown tld: ${tld}`);
};

export const getFileClaimUrl = (policy: CorePolicy, urls: URLs): string => {
  const { country, partner, policyNumber } = policy;
  if (partner == "state-farm") {
    return urls.stateFarm.claim;
  }

  return country === "USA"
    ? urls.insurance.newFileClaim(country)
    : urls.insurance.fileClaim(policyNumber, country);
};
