import { Environment } from "shared";

type AppMetadata = {
  version: string;
};

export const getAppMetadata = (environment: Environment): AppMetadata => {
  if (environment === "localhost") return { version: "0.0.0+local" };

  const metadataError = new Error("Can't locate app metadata");
  const metadataElement = document.getElementById("app-metadata");
  if (!metadataElement) throw metadataError;
  const json = metadataElement.textContent;
  if (!json) throw metadataError;
  const appMetadata: AppMetadata = JSON.parse(json);
  return appMetadata;
};
