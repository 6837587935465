import { Link } from "../Link";
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from "../../svg";
import { HagertyLogo } from "../HagertyLogo";
import styles from "./SubFooter.module.scss";
import { LinkIcon } from "./LinkIcon";
import { useUrls } from "../../app/Providers/AppContext";

export function SubFooter() {
  const urls = useUrls();

  return (
    <div className={styles.subFooter}>
      <div className="container container_center">
        <div className="global-footer__logo-social-links">
          <div className="global-footer__logo">
            <Link href={urls.home} aria-label="Hagerty Logo">
              <HagertyLogo className={styles.logo} />
            </Link>
          </div>
          <div className="global-footer__social-icons">
            <LinkIcon
              href={urls.social.facebook}
              src={Facebook}
              alt="Hagerty on Facebook"
            />
            <LinkIcon
              href={urls.social.instagram}
              src={Instagram}
              alt="Hagerty on Instagram"
            />
            <LinkIcon
              href={urls.social.linkedIn}
              src={LinkedIn}
              alt="Hagerty on LinkedIn"
            />
            <LinkIcon
              href={urls.social.youTube}
              src={YouTube}
              alt="Hagerty on YouTube"
            />
            <LinkIcon
              href={urls.social.twitter}
              src={Twitter}
              alt="Hagerty on Twitter"
            />
          </div>
          <div className="global-footer__nav-links">
            <ul>
              <li>
                <Link href={urls.home}>Home</Link>
              </li>
              <li>
                <Link href={urls.corporate.aboutUs}>About us</Link>
              </li>
              <li>
                <Link href={urls.corporate.careers}>Careers</Link>
              </li>
              <li>
                <Link href={urls.corporate.agents}>Agents</Link>
              </li>
              <li>
                <Link href={urls.corporate.marketplace}>Marketplace</Link>
              </li>
              <li>
                <Link href={urls.corporate.contactUs}>Contact us</Link>
              </li>
              <li>
                <Link href={urls.corporate.faq}>FAQ</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
