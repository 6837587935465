import { ReactNode } from "react";
import { Divider } from "../Divider";
import styles from "./Row.module.scss";
import cx from "clsx";

type RowProps = {
  /** Row label */
  label?: string | ReactNode;

  /** Set to true to mark the label with aria-hidden */
  hideLabelForAria?: boolean;

  /** Set to true to display "Loading..." message instead of the label when the row is currently loading */
  isLoading?: boolean;

  /** Content to render in the dd */
  children?: ReactNode;

  /** Class to apply to the dd */
  className?: string;

  /** An hr-like divider displays at the bottom by default. Set to false to hide. */
  showDivider?: boolean;
};

/** Renders a dt/dd. For use within a `<DL>` */
export const Row = ({
  label,
  isLoading = false,
  hideLabelForAria = false,
  children = null,
  className,
  showDivider = true,
}: RowProps) => {
  const dtProps: any = {
    className: cx(
      "col xs-size_1-of-1 md-size_1-of-2 login-data-table_row-label",
      styles.dt
    ),
  };

  if (hideLabelForAria) dtProps["aria-hidden"] = true;
  if (isLoading) dtProps["aria-busy"] = true;

  return (
    <>
      <dt {...dtProps}>{isLoading ? "Loading..." : label}</dt>
      <dd
        className={cx(
          "col xs-size_1-of-1 md-size_1-of-2 login-data-table_row-body",
          styles.dd,
          styles["login-data-table_row-body"],
          className
        )}
      >
        {children}
      </dd>
      {showDivider && <Divider />}
    </>
  );
};
