import useEventListener from "@use-it/event-listener";

/**
 * Remove the hash before leaving home page.
 * This means that pressing back in the browser will return to the top of the page.
 */
export const useRemoveHashBeforeUnload = (): void => {
  useEventListener("beforeunload", () => {
    if (window.location.pathname === "/") {
      window.history.replaceState({}, "", window.location.origin);
    }
  });
};
