import { useUrls } from "../../app/Providers/AppContext";
import { TagAction } from "../../types/Tag.types";
import { Alert } from "../Alert";
import { Link } from "../Link";
import styles from "./ConnectPolicy.module.scss";

const connectPolicyText = "Connect another policy";

const connectPolicyTagAction: TagAction = {
  actionName: connectPolicyText,
  actionType: "button",
};

/** Renders a link to connect another policy */
export const ConnectPolicy = () => {
  const urls = useUrls();

  return (
    <Alert
      className={styles.root}
      level="default"
      message={
        <div className={styles.message}>
          Do you have an insurance policy you don&apos;t see here?
        </div>
      }
      actionSlot={
        <Link
          href={urls.insurance.connectPolicy(true)}
          tagAction={connectPolicyTagAction}
          className={styles.link}
        >
          {connectPolicyText}
        </Link>
      }
    />
  );
};
