import { Country } from "@hagerty/self-service-components/api/types";
import { Currency } from ".";

export type FormatCurrencyOptions = {
  country: Country;
  decimalPlaces?: number;
};

export const formatCurrency = (
  amount: number,
  options: FormatCurrencyOptions
): string => {
  const { country, decimalPlaces = 2 } = options;
  const currency: Currency = country === "CANADA" ? "CAD" : "USD";

  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD", // If we used CAD we would get "CA$100 CAD" when we add the suffix
    // NOTE: Per the spec, both min and max need to be specified.
    // see discussion: https://github.com/andyearnshaw/Intl.js/issues/123
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  }).format(amount);
  const suffix = currency === "USD" ? "" : " CAD";
  return `${formattedAmount}${suffix}`;
};
