import { State } from "@hagerty/self-service-components/api/types";

import { provincesMap, statesMap } from "./statesMap";

export type StateName =
  | typeof statesMap[keyof typeof statesMap]
  | typeof provincesMap[keyof typeof provincesMap];

const stateToStateNameMap: Record<State, StateName> = {
  ...provincesMap,
  ...statesMap,
} as const;

export const getStateName = (state: State): StateName =>
  stateToStateNameMap[state];
