import { ReactNode } from "react";
import clsx from "clsx";
import styles from "./Pane.module.scss";

type PaneProps = {
  children: ReactNode;
  active: boolean;
};

export const Pane = ({ children, active }: PaneProps) => (
  <div
    className={clsx(styles.pane, {
      [styles.active]: active,
    })}
  >
    {children}
  </div>
);
