import { mt } from "./tracking";

export type OnReadyPayload = {
  isPolicyHolder?: boolean;
  hasOutstandingBalance?: boolean;
  hasHdc?: boolean;
  hasESignDocuments?: boolean;
};

let hasLoggedOnReady = false;

export const logOnReady = (payload: OnReadyPayload) => {
  if (
    hasLoggedOnReady ||
    payload.isPolicyHolder === undefined ||
    payload.hasOutstandingBalance === undefined ||
    payload.hasHdc === undefined
  ) {
    return;
  }

  hasLoggedOnReady = true;

  mt.trackEvent({
    insurance_indicator: payload.isPolicyHolder,
    has_positive_bill_amount: payload.hasOutstandingBalance,
    hdc_membership: payload.hasHdc,
    has_message_displayed: payload.hasESignDocuments,
  });
};
