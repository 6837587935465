import clsx from "clsx";
import { useState } from "react";
import { Alert } from "../Alert";
import { AlertRow } from "./AlertRow";
import { AlertGroupProps } from "./AlertGroup.props";

export function AlertGroup({
  alerts,
  showIcon = false,
  className,
  ...rest
}: AlertGroupProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={clsx("alert__group", className, { expanded: isExpanded })}>
      <Alert
        {...rest}
        title={`You have ${alerts.length} notifications`}
        showIcon={showIcon}
        as="button"
        onClick={() => setIsExpanded(!isExpanded)}
      />
      <div className="alert__panel" aria-hidden={!isExpanded}>
        {alerts.map(({ title, message, actionConfig }) => (
          <AlertRow
            key={`${title}${message}`}
            title={title}
            message={message}
            actionConfig={actionConfig}
          />
        ))}
      </div>
    </div>
  );
}
