import { useState } from "react";
import { useEnvironment, useUrls } from "../../app/Providers/AppContext";
import { PreferencesInfoDialog } from "./PreferencesInfoDialog";
import { Link } from "../Link";
import { useContactId } from "../../app/Providers/AppContext";
import { TagAction } from "../../types/Tag.types";

const label = "Communication preferences";
const ariaLabel = "Manage communication preferences";
const linkText = "Manage preferences";

const managePreferencesTagAction: TagAction = {
  actionName: "Manage preferences",
  actionType: "link",
};

type CommunicationPreferencesProps = {
  styles: Record<string, string>;
};

export function CommunicationPreferences({
  styles,
}: CommunicationPreferencesProps) {
  const urls = useUrls();
  const [showPreferenceInfoDialog, setShowPreferenceInfoDialog] =
    useState(false);
  const contactId = useContactId();
  const environment = useEnvironment();

  // This component renders differently on non-prod for safety since there's only a prod URL.
  // So, we show an explanatory dialog in non-prod.
  function renderLinkOrDialogButton() {
    return environment === "production" ? (
      <Link
        className={styles.editLink}
        ariaLabel={ariaLabel}
        tagAction={managePreferencesTagAction}
        href={urls.account.editPreferences(contactId)}
      >
        {linkText}
      </Link>
    ) : (
      <button
        aria-label={ariaLabel}
        className={styles.buttonLink}
        onClick={() => setShowPreferenceInfoDialog(true)}
      >
        {linkText}
      </button>
    );
  }

  return (
    <div className={styles.rowWrapperCommunication}>
      <dt className={styles.descriptionText}>{label}</dt>
      <dd>{renderLinkOrDialogButton()}</dd>

      {showPreferenceInfoDialog && (
        <PreferencesInfoDialog
          onCloseDialog={() => setShowPreferenceInfoDialog(false)}
        />
      )}
    </div>
  );
}
