import styles from "./NoDriversClub.module.scss";
import { DriversClubLogo } from "./DriversClubLogo";
import { Button } from "../Button";
import { useUrls } from "../../app/Providers/AppContext";
import { TagAction } from "../../types/Tag.types";

export const titleText = "Unlock all your benefits";
export const linkText = "Learn more";

export const benefits = [
  "Exclusive automotive offers",
  "Expert support",
  "Members-only experiences",
  "Original content",
  "Emergency services",
] as const;

export const learnMoreTagAction: TagAction = {
  actionType: "link",
  actionName: linkText,
};

export const NoDriversClub = () => {
  const urls = useUrls();

  return (
    <div className={styles.container}>
      <DriversClubLogo className={styles.logo} />
      <h2>{titleText}</h2>
      <ul>
        {benefits.map((benefit, index) => (
          <li key={index}>{benefit}</li>
        ))}
      </ul>
      <Button
        href={urls.hdc.redeemBenefits}
        className={styles.button}
        tagAction={learnMoreTagAction}
      >
        {linkText}
      </Button>
    </div>
  );
};
