import styles from "./SignDocumentsDialog.module.scss";
import { Dialog, DialogButton, DialogButtonGroup } from "../Dialog";
import { PendingPane } from "./PendingPane";
import { SuccessPane } from "./SuccessPane";
import { ErrorPane } from "./ErrorPane";
import { mt } from "../../utils/tracking";
import { TagAction } from "../../types/Tag.types";
import { useEffect } from "react";

export type Status = "pending" | "success" | "error";

export const dialogTitle = "Sign documents";

const resendTagAction: TagAction = {
  actionName: "Resend",
  actionType: "button",
};

export type SignDocumentsDialogProps = {
  status: Status;
  onDismiss?: () => void;
  resend: () => void;
  policyName: string;
  email: string;
};

export const SignDocumentsDialog = ({
  resend,
  onDismiss,
  status,
  policyName,
  email,
}: SignDocumentsDialogProps) => {
  useEffect(() => {
    mt.trackPopup("Sign documents");
  }, []);

  const closeDialog = () => {
    mt.trackPopupClose();
    onDismiss?.();
  };

  const isPending = status === "pending";

  const buttons = (
    <>
      <DialogButton
        disabled={isPending}
        variant="secondary"
        onClick={() => {
          mt.trackAction(resendTagAction);
          resend();
        }}
        aria-label="Resend email"
      >
        Resend
      </DialogButton>
      <DialogButton
        disabled={isPending}
        onClick={closeDialog}
        aria-label="Close dialog"
      >
        Done
      </DialogButton>
    </>
  );

  return (
    <Dialog
      showCloseButton={isPending ? "disabled" : "yes"}
      onDismiss={closeDialog}
      title={dialogTitle}
      role="alert"
      ariaBusy={isPending}
      footer={
        <DialogButtonGroup className={styles.buttonGroup}>
          {buttons}
        </DialogButtonGroup>
      }
    >
      <div className={styles.container}>
        <PendingPane active={isPending} policyName={policyName} />
        <SuccessPane
          active={status === "success"}
          policyName={policyName}
          email={email}
        />
        <ErrorPane
          active={status === "error"}
          policyName={policyName}
          email={email}
        />
      </div>
    </Dialog>
  );
};
