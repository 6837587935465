import { Suspense, ReactElement } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ErrorRow } from "../ui/ErrorRow";
import { LoadingRow } from "../ui/LoadingRow/LoadingRow";

const defaultLoading = <LoadingRow />;

type WithSuspenseOptions = {
  loading?: ReactElement;
  FallbackComponent?: React.ComponentType<FallbackProps>;
};

const withSuspense = <T,>(
  WrappedComponent: any,
  options: WithSuspenseOptions = {}
) => {
  const { loading = defaultLoading, FallbackComponent = ErrorRow } = options;

  return function WrappedWithSuspense(props: T) {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Suspense fallback={loading}>
          <WrappedComponent {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };
};

withSuspense.displayName = "withSuspense";

export default withSuspense;
