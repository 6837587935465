import { MouseEventHandler } from "react";
import { SectionHash } from "../../app/Providers/ActiveSectionContext";
import styles from "./SubMenu.module.scss";

type SubMenuItemProps = {
  title: string;
  hash: SectionHash;
  icon: React.FunctionComponent;
  isActive: boolean;
  onClick: MouseEventHandler<HTMLAnchorElement>;
};

const { origin } = window.location;

export const SubMenuItem = ({
  title,
  hash,
  icon: Icon,
  isActive,
  onClick,
}: SubMenuItemProps) => {
  return (
    <li
      className={`submenu__item ${styles.menuWrapper}`}
      data-active={isActive}
    >
      <a
        href={`${origin}#${hash}`}
        aria-current={isActive ? "location" : undefined}
        onClick={onClick}
        className="submenu__link link"
      >
        <div className={styles.menuImage}>
          <Icon aria-hidden={true} />
        </div>
        {title}
      </a>
    </li>
  );
};
