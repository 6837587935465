import React, { ForwardedRef } from "react";

interface FormSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  items: { [abbr: string]: string };
  value?: string;
  defaultValue?: string;
}

export const FormSelect = React.forwardRef(
  (
    { name, items, defaultValue, value, ...rest }: FormSelectProps,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    return (
      <div className="form-element__control">
        <div className="input-select__wrapper">
          <select
            defaultValue={defaultValue}
            ref={ref}
            className="input-select"
            value={value}
            {...rest}
          >
            {Object.entries(items).map(([abbr, name]) => (
              <option key={abbr} value={abbr}>
                {name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
);
