import styles from "./PleaseCall.module.scss";

type PleaseCallProps = {
  phoneNumber: string;
};

export const PleaseCall = ({ phoneNumber }: PleaseCallProps) => {
  return (
    <span aria-hidden="true">
      Please call{" "}
      <a className={styles.phoneLink} href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </a>
      .
    </span>
  );
};
