import { DataIsland } from "shared";
import { decodeHtml } from "./decodeHtml";

const invalidDataIsland: DataIsland = {
  status: "error",
  error: "Invalid Data Island",
  logoutId: "",
};

export const parseDataIsland = (
  accountDataElement: HTMLElement | null
): DataIsland => {
  if (accountDataElement) {
    const textContent = accountDataElement.textContent;
    if (textContent) {
      try {
        const decodedTextContent = decodeHtml(textContent);
        const dataIsland = JSON.parse(decodedTextContent);
        if (
          dataIsland?.status === "success" ||
          dataIsland?.status === "error"
        ) {
          return dataIsland;
        }
      } catch {
        return invalidDataIsland;
      }
    }
  }
  return invalidDataIsland;
};
