import { VehicleProps } from "./Vehicle.types";
import { Link } from "../../Link";
import { getVehicleName } from "../../../utils/vehicleUtils";
import style from "./Vehicle.module.scss";
import { formatCurrency } from "shared";
import { useUrls, useFeatureFlag } from "../../../app/Providers/AppContext";
import { TagAction } from "../../../types/Tag.types";
import { useDialog } from "../../Dialog";
import { RemoveVehicleDialog } from "../RemoveVehicleDialog";
import { RemoveLastVehicleDialog } from "../RemoveLastVehicleDialog";
import { mt } from "../../../utils/tracking";
import { useState } from "react";

const editVehicleTagAction: TagAction = {
  actionName: "Edit",
  actionType: "link",
};
const removeVehicleTagAction: TagAction = {
  actionName: "Remove",
  actionType: "link",
};

export const Vehicle = ({ vehicle, policy }: VehicleProps) => {
  const [redirectEditVehicle, setRedirectEditVehicle] = useState(false);
  const urls = useUrls();
  const { REMOVE_VEHICLE } = useFeatureFlag();
  const { close, open, isOpen } = useDialog();
  const { value, policyItemNumber, vin } = vehicle;
  const { policyNumber, country } = policy;
  const vehicleName = getVehicleName(vehicle);
  const shouldRenderVehicleActions =
    policy.source === "core" && policy.partner !== "state-farm";

  const removeVehicleDialog =
    policy.vehicles.length > 1 ? (
      <RemoveVehicleDialog vehicle={vehicle} policy={policy} onClose={close} />
    ) : (
      <RemoveLastVehicleDialog policy={policy} onClose={close} />
    );

  return (
    <>
      <section aria-label={vehicleName} className={style.rowOneWrapper}>
        <div className={style.vehicleWrapper}>
          <h4 title={vehicleName} className={style.vehicleDescriptionText}>
            {vehicleName}
          </h4>
          <div className={style.detailsRow}>
            <span>
              Value: {formatCurrency(value, { country, decimalPlaces: 0 })}
            </span>
            <span>VIN: {vin}</span>
          </div>
        </div>
        {shouldRenderVehicleActions && (
          <div className={style.actionWrapper}>
            <Link
              className={style.actionLink}
              ariaLabel={`Edit your ${vehicleName} vehicle details on policy ${policyNumber}`}
              href={urls.insurance.editVehicle(
                policyNumber,
                policyItemNumber,
                country
              )}
              isLoading={redirectEditVehicle}
              onClick={() => setRedirectEditVehicle(true)}
              tagAction={editVehicleTagAction}
            >
              Edit
            </Link>
            {REMOVE_VEHICLE.treatment === "on" && (
              <button
                aria-label={`Remove your ${vehicleName} from policy ${policyNumber}`}
                className={`${style.actionLink} ${style.removeButton}`}
                onClick={() => {
                  mt.trackAction(removeVehicleTagAction);
                  open();
                }}
              >
                Remove
              </button>
            )}
          </div>
        )}
      </section>
      {isOpen && removeVehicleDialog}
    </>
  );
};
