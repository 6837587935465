import { Country } from "@hagerty/self-service-components/api/types";
import { formatCurrency } from "shared";

export type CurrencyProps = {
  ariaLabel: string;
  amount: number;
  decimalPlaces?: number;
  country: Country;
};

/** Renders a formatted currency amount with an `aria-label` */
export const Currency = ({
  amount,
  ariaLabel,
  decimalPlaces,
  country,
}: CurrencyProps) => {
  return (
    <span aria-label={ariaLabel}>
      {formatCurrency(amount, { country, decimalPlaces })}
    </span>
  );
};
