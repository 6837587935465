import styles from "./Address.module.scss";

type AddressProps = {
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  fallback?: JSX.Element | null;
};

// Only add a comma delimiter and space if both the city and state are populated
export function formatCityState(city: string, state: string) {
  return city && state ? `${city}, ${state}` : city + state;
}

export const Address = ({
  line1,
  line2,
  city,
  state,
  postalCode,
  fallback = null,
}: AddressProps) => {
  const cityState = formatCityState(city, state);

  let part1 = line1;
  if (line2) part1 += ` ${line2}`;
  // Note, only adding a comma after line 1 if city, state, or postal code are populated.
  if (line1 && (cityState || postalCode)) part1 += ",";

  let part2 = cityState || postalCode ? `${cityState}` : "";
  if (postalCode) part2 += ` ${postalCode}`;

  const hasAddress = part1 || part2;
  return hasAddress ? (
    <address aria-label="Mailing address">
      <span>{part1}</span>
      {part2 && " "}
      <span className={styles.nowrap}>{part2}</span>
    </address>
  ) : (
    fallback
  );
};
