import clsx from "clsx";
import { LoadOnLight } from "../../svg";
import styles from "./Spinner.module.scss";

export type SpinnerProps = {
  className?: string;
};

export const Spinner = ({ className }: SpinnerProps) => {
  return (
    <img
      aria-hidden="true"
      className={clsx(styles.spinner, className)}
      src={LoadOnLight}
      alt="loading indicator"
    />
  );
};
