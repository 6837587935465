import { Link } from "./types/SubMenu.types";

const filterBilling = ({ hash }: Link) => hash !== "billing";
const filterBillingAndHdc = ({ hash }: Link) =>
  hash !== "billing" && hash !== "hdc";

export const getSubMenuLinks = (
  allLinks: Link[],
  shouldShowBilling: boolean,
  shouldShowHdc: boolean
) => {
  if (shouldShowBilling) return allLinks;
  const filterFn = shouldShowHdc ? filterBilling : filterBillingAndHdc;
  return allLinks.filter(filterFn);
};
