import usFlag from "@hagerty/design-system/src/assets/icons/24/24-flag-us.svg";
import caFlag from "@hagerty/design-system/src/assets/icons/24/24-flag-canada.svg";
import ukFlag from "@hagerty/design-system/src/assets/icons/24/24-flag-uk.svg";
import { useTld, useUrls } from "../../app/Providers/AppContext";
import "./GlobalFooter.scss";
import { SubFooter } from "./SubFooter";
import { Link } from "../Link";

const Flags = () => {
  const urls = useUrls();
  const flagsMap = {
    [usFlag]: {
      href: urls.corporate.country.usa,
      country: "USA",
    },
    [caFlag]: {
      href: urls.corporate.country.canada,
      country: "Canada",
    },
    [ukFlag]: {
      href: urls.corporate.country.uk,
      country: "United Kingdom",
    },
  };

  return (
    <div className="global-footer__flags">
      {Object.entries(flagsMap).map(([src, { href, country }]) => (
        <a
          key={src}
          className="global-footer__flag"
          href={href}
          aria-label={`Hagerty ${country} website`}
        >
          <img src={src} className="icon icon_large" alt={`${country} flag`} />
        </a>
      ))}
    </div>
  );
};

/** Site footer */
export function GlobalFooter() {
  const urls = useUrls();
  const tld = useTld();

  return (
    <footer className="global-footer">
      <nav aria-label="Footer">
        <SubFooter />
        <div className="container container_center">
          <div className="global-footer__simple-footer">
            <div className="global-footer__utility-links">
              <ul>
                {tld === "com" && (
                  <li>
                    <Link href={urls.corporate.accessibility}>
                      Accessibility
                    </Link>
                  </li>
                )}
                <li>
                  <Link href={urls.corporate.disclosures}>Disclosures</Link>
                </li>
                <li>
                  <Link href={urls.corporate.privacyPolicy}>Privacy</Link>
                </li>
                <li>
                  <Link href={urls.corporate.termsOfUse}>Terms of use</Link>
                </li>
              </ul>
            </div>
            <div className="global-footer__copyright-wrapper">
              <Flags />
              <div className="copyright-label">
                &copy; 1996 – {new Date().getFullYear()} The Hagerty Group, LLC
              </div>
            </div>
          </div>
        </div>
      </nav>
    </footer>
  );
}
