import { createContext, ReactNode, useState } from "react";
import { createWrappedContext } from "../../hooks/createWrappedContext";

type PolicyContext = {
  /** Determines if customer is a policy holder. */
  isPolicyHolder: boolean | undefined;

  /** Set isPolicyHolder. */
  setIsPolicyHolder: (isPolicyHolder: boolean) => void;
};

const Context = createContext<PolicyContext | null>(null);
const useWrappedContext = createWrappedContext<PolicyContext | null>({
  context: Context,
  providerName: "PolicyContextProvider",
});

type PolicyContextProviderProps = {
  children: ReactNode;
};

/** Provider for global application context */
export const PolicyContextProvider = ({
  children,
}: PolicyContextProviderProps) => {
  // Defaults to undefined until policy call is returned.
  const [isPolicyHolder, setIsPolicyHolder] = useState<boolean | undefined>(
    undefined
  );

  const value = { isPolicyHolder, setIsPolicyHolder };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/** Hook to get the isPolicyHolder and setIsPolicyHolder from app context */
export const useIsPolicyHolder = () => useWrappedContext("useIsPolicyHolder");
