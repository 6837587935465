import { DateRange } from "../DateRange";

type EffectiveFromProps = {
  effectiveDate: string;
  expirationDate?: string;
};

export const EffectiveFrom = ({
  effectiveDate,
  expirationDate,
}: EffectiveFromProps) => (
  <>
    Effective from: <DateRange start={effectiveDate} end={expirationDate} />
  </>
);
