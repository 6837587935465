import { useRef } from "react";
import styles from "./NoInsurance.module.scss";
import { Card } from "../Card";
import { NoInsurancePresentation } from "./NoInsurancePresentation";
import { NoInsuranceWithoutConnectPolicy } from "./NoInsuranceWithoutConnectPolicy";
import { Section } from "../Section";
import { SectionHeader } from "../SectionHeader";
import { useCountry, useUrls } from "../../app/Providers/AppContext";
import { PartnerLogo } from "../PartnerLogo/PartnerLogo";

export const titleText = "Have an insurance policy with us?";
export const subTitleText =
  "Connect your policy to make payments, edit your policy, view statements, manage claims, and more.";
export const connectPolicyText = "Connect policy";
export const footerText = "Don't have insurance with us?";
export const heading = "Insurance Policy";
export const footerLinkText = "Get a quote for free";

export const ssoTitleText = "Want to insure your classic vehicle?";
export const ssoSubTitleText = "Contact your State Farm agent.";

type NoInsuranceProps = {
  isStateFarmSso: boolean;
  isStateFarmPartner: boolean;
};

export const NoInsurance = ({
  isStateFarmSso,
  isStateFarmPartner,
}: NoInsuranceProps) => {
  const urls = useUrls();
  const country = useCountry();
  const headerRef = useRef<HTMLHeadingElement | null>(null);

  return (
    <Section ariaLabel={heading}>
      {isStateFarmPartner && <PartnerLogo partner={"state-farm"} />}
      <SectionHeader heading={heading} headerRef={headerRef} id="insurance" />
      <Card width="full" className={styles.container}>
        {isStateFarmSso ? (
          <NoInsuranceWithoutConnectPolicy
            titleText={ssoTitleText}
            subTitleText={ssoSubTitleText}
            styles={styles}
          />
        ) : (
          <NoInsurancePresentation
            titleText={titleText}
            subTitleText={subTitleText}
            connectPolicyText={connectPolicyText}
            footerText={footerText}
            footerLinkText={footerLinkText}
            footerLinkUrl={urls.insurance.getQuote(country)}
            styles={styles}
          />
        )}
      </Card>
    </Section>
  );
};
