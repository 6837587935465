import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { useUrls } from "../../../app/Providers/AppContext";
import { Popover, Card } from "@hagerty/self-service-components";
import { useRef, useState } from "react";
import { Documents } from "../../../svg";
import { ReactComponent as DownChevron } from "../../../svg/chevron-down.svg";
import { Tile } from "../../Tile";
import styles from "./PolicyTiles.module.scss";
import { Link } from "../../Link";
import { TagAction } from "../../../types/Tag.types";

type DocumentsTileProps = {
  policy: CorePolicy;
};

const policyDocumentsTagAction: TagAction = {
  actionName: "Policy documents",
  actionType: "link",
};
const billingStatementsTagAction: TagAction = {
  actionName: "Billing statements",
  actionType: "link",
};
const uploadDocumentsTagAction: TagAction = {
  actionName: "Upload documents",
  actionType: "link",
};
const changeDocumentDeliveryTagAction: TagAction = {
  actionName: "Change document delivery",
  actionType: "link",
};

export const DocumentsTile = ({ policy }: DocumentsTileProps) => {
  const [redirect, setRedirect] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const { country, policyNumber, partner } = policy;
  const urls = useUrls();
  const isStateFarm = partner === "state-farm";

  const hidePopover = () => {
    setIsPopOverOpen(false);
    triggerRef?.current?.blur();
  };

  const onClickLink = () => {
    hidePopover();
    setRedirect(true);
  };

  return (
    <>
      <Tile
        icon={<img src={Documents} alt="Documents" aria-hidden="true" />}
        className={styles.tile}
        cta="Documents"
        onClick={() => setIsPopOverOpen(!isPopOverOpen)}
        ctaIcon={<DownChevron />}
        ref={triggerRef}
        isLoading={redirect}
      />
      <Popover
        triggerRef={triggerRef}
        onClose={() => setTimeout(hidePopover)}
        isOpen={isPopOverOpen}
        placement={"bottom start"}
        className={styles.popover}
        restoreFocus={false}
      >
        <Card className={styles.card}>
          <Link
            tagAction={policyDocumentsTagAction}
            onClick={onClickLink}
            href={urls.insurance.viewPolicy(policyNumber, country, isStateFarm)}
          >
            Policy documents
          </Link>
          <Link
            tagAction={billingStatementsTagAction}
            onClick={onClickLink}
            href={urls.insurance.billingStatements(policyNumber)}
          >
            Billing statements
          </Link>
          <Link
            tagAction={uploadDocumentsTagAction}
            onClick={onClickLink}
            href={urls.insurance.uploadDocuments(country)}
          >
            Upload documents
          </Link>
          <Link
            tagAction={changeDocumentDeliveryTagAction}
            onClick={onClickLink}
            href={urls.insurance.changeDocumentDelivery(country)}
          >
            Change document delivery
          </Link>
        </Card>
      </Popover>
    </>
  );
};
