import { createContext, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { createWrappedContext } from "../../hooks/createWrappedContext";
import { Policy } from "@hagerty/self-service-components/api/types";
import { useFeatureFlag } from "../../app/Providers/AppContext";

type InsuranceContext = {
  /** Determines if customer has at least one policy with too many vehicles. It is limited by dynamic config
        of REMOVE_VEHICLE feature flag (max_vehicle_count) */
  hasTooManyVehiclesOnSinglePolicy: boolean;
};

const Context = createContext<InsuranceContext | null>(null);
const useWrappedContext = createWrappedContext<InsuranceContext | null>({
  context: Context,
  providerName: "InsuranceContextProvider",
});

type InsuranceContextProviderProps = {
  policies: Policy[];
  children: ReactNode;
};

export const InsuranceContextProvider = ({
  children,
  policies,
}: InsuranceContextProviderProps) => {
  const { REMOVE_VEHICLE } = useFeatureFlag();
  let hasTooManyVehiclesOnSinglePolicy = true;

  // config is only sent if REMOVE_VEHICLE="on"
  if (REMOVE_VEHICLE.treatment === "on") {
    const maxVehicleCount = REMOVE_VEHICLE.config?.max_vehicle_count;

    if (maxVehicleCount) {
      hasTooManyVehiclesOnSinglePolicy = policies.some(
        (policy) => policy.vehicles.length > +maxVehicleCount
      );
    } else {
      Sentry.captureMessage("REMOVE_VEHICLE config is undefined", "warning");
    }
  }

  return (
    <Context.Provider value={{ hasTooManyVehiclesOnSinglePolicy }}>
      {children}
    </Context.Provider>
  );
};

/** Hook to get the hasTooManyVehiclesOnSinglePolicy context */
export const useInsuranceContext = () =>
  useWrappedContext("useInsuranceContext");
