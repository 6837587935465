import { ReactNode } from "react";

type DialogButtonGroupProps = {
  children: ReactNode;
  className?: string;
};

export const DialogButtonGroup = ({
  children,
  className = "",
}: DialogButtonGroupProps) => (
  <div className="modal__actions">
    <div className={`modal__actions-right button-group ${className}`}>
      {children}
    </div>
  </div>
);
