import { Vehicle } from "@hagerty/self-service-components/api/types";

//  Returns vehicle name with year, make, and model
export const getVehicleName = (vehicle: Vehicle): string => {
  const { make, model, year } = vehicle;
  return `${year} ${make} ${model}`;
};

// Returns vehicle labels for a policy
// Example:  "1962 Ferrari 250 GTO or 1968 Dodge Super Bee"
export const getVehiclesLabel = (vehicles: Vehicle[]): string => {
  let vehicleNamesLabel = "";
  vehicles.forEach((vehicle) => {
    if (vehicleNamesLabel === "") vehicleNamesLabel = getVehicleName(vehicle);
    else {
      vehicleNamesLabel += " or " + getVehicleName(vehicle);
    }
  });
  return vehicleNamesLabel;
};
