import { DriversClubLayout } from "./DriversClubLayout";
import { CallDriversClubSupport } from "./CallDriversClubSupport";
import { useDriversClub } from "./useDriversClub";
import { NoDriversClub } from "./NoDriversClub";
import { DriversClub as DriversClubType } from "shared";
import { DriversClubTiles } from "./DriversClubTiles";

export type DriversClubProps = {
  shouldShowHdc?: boolean;
};

/** Renders the "Hagerty Drivers Club" region */
export const DriversClub = ({ shouldShowHdc = true }: DriversClubProps) => {
  const hdc = useDriversClub();
  return shouldShowHdc && <DriversClubPresentation hdc={hdc} />;
};

type DriversClubPresentationProps = {
  hdc: DriversClubType;
};

// We split up this component because any state change (like displaying a dialog)
// would cause the DriversClub component to rerender and call useDriversClub again,
// which would needlessly refetch /api/hdc.
const DriversClubPresentation = ({ hdc }: DriversClubPresentationProps) => {
  if (!hdc) {
    return (
      <DriversClubLayout>
        <NoDriversClub />
      </DriversClubLayout>
    );
  }

  const {
    hdcVersion,
    effectiveDate,
    expirationDate,
    membershipId,
    benefits,
    membershipLevel,
  } = hdc;

  return (
    <DriversClubLayout
      membershipId={membershipId}
      membershipLevel={membershipLevel}
      effectiveDate={effectiveDate}
      expirationDate={expirationDate}
      benefits={benefits}
    >
      <DriversClubTiles hdc={hdc} />

      {hdcVersion === "1.0" && <CallDriversClubSupport />}
    </DriversClubLayout>
  );
};
