import { phoneNumbers } from "shared";

import { Alert } from "../Alert";
import { Link } from "../Link";
import styles from "./DownloadDocumentErrorAlert.module.scss";
import { useEffect } from "react";
import { mt } from "../../utils/tracking";

type DownloadDocumentErrorAlertProps = {
  documentName: string;
  onClose: () => void;
};

export const DownloadDocumentErrorAlert = ({
  documentName,
  onClose,
}: DownloadDocumentErrorAlertProps) => {
  useEffect(() => {
    mt.trackError({
      errorType: `Sorry, we are unable to retrieve your ${documentName} at this time`,
      errorDetails: `Please try again or call ${phoneNumbers.auto} for assistance`,
    });
  }, []);

  return (
    <Alert
      className={styles.alert}
      onClose={() => onClose()}
      closeButtonAriaLabel="Close error alert"
      showIcon
      level="danger"
      message={
        <div className={styles.message}>
          <div>
            Sorry, we are unable to retrieve your {documentName} at this time.
            Please try again or call <Link phoneNumber={phoneNumbers.auto} />{" "}
            for assistance.
          </div>
        </div>
      }
    />
  );
};
