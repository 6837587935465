import withSuspense from "../../hocs/withSuspense";
import { Insurance as InsuranceUnwrapped } from "./Insurance";
import { InsuranceLoading } from "./InsuranceLoading";
import { InsuranceErrorFallback } from "./InsuranceErrorFallback";

type InsuranceFallbackProps = {
  resetErrorBoundary: () => void;
};

const InsuranceFallback = ({ resetErrorBoundary }: InsuranceFallbackProps) => {
  return <InsuranceErrorFallback resetErrorBoundary={resetErrorBoundary} />;
};

export const Insurance = withSuspense(InsuranceUnwrapped, {
  FallbackComponent: InsuranceFallback,
  loading: <InsuranceLoading />,
});
