import { useRef } from "react";
import { useActiveSection } from "../../hooks/useActiveSection";
import { Section } from "../Section";
import { SectionHeader } from "../SectionHeader";
import { ErrorRow } from "../ErrorRow";

type InsuranceErrorFallbackProps = {
  resetErrorBoundary: () => void;
};

export const InsuranceErrorFallback = ({
  resetErrorBoundary,
}: InsuranceErrorFallbackProps) => {
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  useActiveSection(headerRef, "insurance");
  return (
    <Section ariaLabel="Insurance">
      <SectionHeader
        heading={"Insurance"}
        id="insurance"
        headerRef={headerRef}
      />
      <dl className="grid grid-wrap collapsed-gutters login-data-table_row">
        <ErrorRow resetErrorBoundary={resetErrorBoundary} />
      </dl>
    </Section>
  );
};
