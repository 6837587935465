import { TagAction, TagAlert, TagError } from "../types/Tag.types";
import { MTLibrary, MTPayload, WindowWithTracking } from "../types/MT.types";
import { getEnvironment } from "./getEnvironment";

type MTMethodNames = keyof MTLibrary;
type MTMethodArgs = {
  [K in MTMethodNames]: Parameters<MTLibrary[K]>;
};

const track = <T extends MTMethodNames>(
  method: T,
  ...args: MTMethodArgs[T]
) => {
  const environment = getEnvironment(window.location.hostname);

  if (environment === "localhost") {
    console.log(method, args);
    return;
  }

  const { MT } = window as WindowWithTracking;

  if (MT) {
    const mtTrackingMethod = MT[method] as (...a: typeof args) => void;
    mtTrackingMethod(...args);
  }
};

export const mt = {
  trackAction: ({ actionType, actionName }: TagAction) => {
    track("trackAction", actionType, actionName);
  },
  trackAlert: ({ alertType, alertDetails, payload }: TagAlert) => {
    track("trackAlert", alertType, alertDetails, payload);
  },
  trackError: ({ errorType, errorDetails, payload }: TagError) => {
    track("trackError", errorType, errorDetails, payload);
  },
  trackEvent: (payload?: MTPayload) => {
    track("trackEvent", payload);
  },
  trackPopup: (name: string) => {
    track("trackPopup", name);
  },
  trackPopupClose: () => {
    track("trackPopupClose");
  },
  trackView: (payload?: MTPayload) => {
    track("trackView", payload);
  },
};
