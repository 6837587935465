import { Customer } from "@hagerty/self-service-components/api/types/Customer.types";

export const getCustomerAge = ({ DateOfBirth }: Customer) => {
  const dateOfBirth = new Date(DateOfBirth);
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const m = today.getMonth() - dateOfBirth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
    age--;
  }
  return age;
};
