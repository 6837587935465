import { CorePolicy } from "@hagerty/self-service-components/api/types";
import styles from "./PolicyInfo.module.scss";
import { ListCard, Tooltip } from "@hagerty/self-service-components";
import { ReactComponent as TooltipIcon } from "../../../svg/tooltip.svg";
import { formatPolicyNumber } from "../../../utils/formatPolicyNumber";
import { DateRange } from "../../DateRange";
import { Currency } from "../../Currency";
import { Address } from "../../Address";
import { useUrls } from "../../../app/Providers/AppContext";
import { Link } from "../../Link";
import { PolicyInfoBroker } from "./PolicyInfoBroker";
import clsx from "clsx";
import { useState } from "react";

type PolicyInfoProps = {
  policy: CorePolicy;
};

export const PolicyInfo = ({ policy }: PolicyInfoProps) => {
  const [redirectEditAddress, setRedirectEditAddress] = useState(false);
  const urls = useUrls();
  const {
    policyNumber,
    effectiveDate,
    expirationDate,
    finalPremium,
    country,
    policyAddress,
  } = policy;
  return (
    <ListCard className={styles.wrapper}>
      <ListCard.Row>
        <div className={styles.column}>
          <div className={styles.name}>Policy number</div>
          <div className={styles.details}>
            #{formatPolicyNumber(policyNumber)}
          </div>
        </div>
      </ListCard.Row>
      <ListCard.Row>
        <div className={styles.column}>
          <div className={styles.name}>Effective from</div>
          <div className={styles.details}>
            <DateRange start={effectiveDate} end={expirationDate} />
          </div>
        </div>
      </ListCard.Row>
      {finalPremium && (
        <ListCard.Row>
          <div className={styles.column}>
            <div className={styles.name}>Annual Premium</div>
            <div className={styles.details}>
              <div>
                <Currency
                  amount={finalPremium}
                  decimalPlaces={2}
                  ariaLabel={`Policy ${policyNumber} annual premium ${finalPremium} value`}
                  country={country}
                />{" "}
                / year
              </div>
            </div>
          </div>
        </ListCard.Row>
      )}
      <ListCard.Row>
        <div className={styles.column}>
          <div className={styles.name}>
            Policy address
            <Tooltip
              content="This is the address where your vehicles are registered."
              id="policyAddressTooltip"
              className={styles.tooltip}
              placement="top"
            >
              <TooltipIcon className={styles.tooltipIcon} />
            </Tooltip>
          </div>
          <div className={styles.details}>
            <Address
              line1={policyAddress.LineOne}
              line2={policyAddress.LineTwo}
              city={policyAddress.City}
              state={policyAddress.StateRegion}
              postalCode={policyAddress.PostalCode}
            />
            <Link
              className={clsx(styles.link, styles.action)}
              href={urls.account.changeAddress}
              isLoading={redirectEditAddress}
              onClick={() => setRedirectEditAddress(true)}
            >
              Edit
            </Link>
          </div>
        </div>
      </ListCard.Row>
      <PolicyInfoBroker policy={policy} />
    </ListCard>
  );
};
