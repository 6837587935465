import { CreateESignRequestBody, ESignDocument } from "shared";

export const postESignDocument = async (
  eSignDocument: ESignDocument
): Promise<boolean> => {
  const body: CreateESignRequestBody = {
    PolicyRequiredDocumentPk: eSignDocument.id,
    Source: eSignDocument.source,
  };

  try {
    const response = await fetch(`${window.location.origin}/api/e-sign`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return response.ok;
  } catch {
    return false;
  }
};
