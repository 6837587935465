import withSuspense from "../../hocs/withSuspense";
import { DriversClubFallback } from "./DriversClubFallback";
import { DriversClubLayout } from "./DriversClubLayout";
import { LoadingRow } from "../LoadingRow";

import {
  DriversClub as DriversClubUnwrapped,
  DriversClubProps,
} from "./DriversClub";

export const DriversClub = withSuspense<DriversClubProps>(
  DriversClubUnwrapped,
  {
    FallbackComponent: DriversClubFallback,
    loading: (
      <DriversClubLayout>
        <LoadingRow />
      </DriversClubLayout>
    ),
  }
);
