import { ErrorRow } from "../ErrorRow";
import { DriversClubLayout } from "./DriversClubLayout";

type DriversClubFallbackProps = {
  resetErrorBoundary: () => void;
};

export function DriversClubFallback({
  resetErrorBoundary,
}: DriversClubFallbackProps) {
  return (
    <DriversClubLayout>
      <ErrorRow resetErrorBoundary={resetErrorBoundary} />
    </DriversClubLayout>
  );
}
