import { Link } from "../Link";
import styles from "./ErrorPane.module.scss";
import failedUrl from "../../svg/failed.svg";
import { Pane } from "./Pane";
import { phoneNumbers } from "shared";

type ErrorPaneProps = {
  active: boolean;
  policyName: string;
  email: string;
};

export const ErrorPane = ({ active, policyName, email }: ErrorPaneProps) => (
  <Pane active={active}>
    <img aria-hidden="true" src={failedUrl} alt="email failed" />
    <h3>Couldn’t send {policyName} policy documents</h3>
    <p>
      We tried to send your documents to <strong>{email}</strong> but there was
      an error. Try again or contact us for help at{" "}
      <Link className={styles.phoneNumber} phoneNumber={phoneNumbers.auto} />.
    </p>
  </Pane>
);
