import { Link } from "../Link";

type LinkIconProps = {
  href: string;
  src: string;
  alt: string;
};

export function LinkIcon({ href, src, alt }: LinkIconProps) {
  return (
    <Link href={href}>
      <img className="icon icon_dark" src={src} alt={alt} />
    </Link>
  );
}
