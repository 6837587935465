// Eventually benefits will come from the new API design, but for now, compute using a
// similar method that we used to use in the SPA.
import { Benefits } from ".";

type LevelMap = {
  [key: string]: Benefits;
};

export const hdc1Dot0BenefitsMap: LevelMap = {
  US1: { distance: 20, coversAllVehicles: false },
  CAN1: { distance: 30, coversAllVehicles: false },
  US2: { distance: 100, coversAllVehicles: false },
  CAN2: { distance: 80, coversAllVehicles: false },
  US3: { distance: 150, coversAllVehicles: true },
  CAN3: { distance: 200, coversAllVehicles: true },
  USVIP: { distance: 150, coversAllVehicles: true },
  CANVIP: { distance: 200, coversAllVehicles: true },
} as const;

export const getHdc1Dot0Benefits = (
  productCode: string
): Benefits | undefined => {
  const splitProductCodeIntoCountryAndLevel = /^(US|CAN)MEMB(HDC)?(.*)$/;
  const groups = splitProductCodeIntoCountryAndLevel.exec(productCode) || [];

  const [, country = "", , level = ""] = groups;
  const key = `${country}${level}`;
  const benefits = hdc1Dot0BenefitsMap[key];

  return benefits;
};
