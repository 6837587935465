import styles from "./SubHeader.module.scss";

type SubHeaderProps = {
  /** Array of elements to render, in order */
  elements: React.ReactNode[];
};

/** Display the section subheader with optional additional information */
export const SubHeader = ({ elements }: SubHeaderProps) => (
  <div className={styles.subHeader}>
    {elements.map(
      (element, index) =>
        !!element && (
          <span key={index} className={styles.subHeadingText}>
            {element}
          </span>
        )
    )}
  </div>
);
