import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { getStateName } from "shared";
import styles from "./PolicyTiles.module.scss";
import { useDownloadIdCard } from "../../Insurance/useDownloadIdCard";
import { Tile } from "../../Tile";
import { IdCard } from "../../../svg";
import { TagAction } from "../../../types/Tag.types";
import { useDialog } from "../../Dialog";
import { useEffect, useState } from "react";
import { CompOnlyDialog } from "../../Insurance/CompOnlyDialog";
import { DownloadDocumentErrorAlert } from "../../Insurance/DownloadDocumentErrorAlert";
import { getVehiclesLabel } from "../../../utils/vehicleUtils";
import { mt } from "../../../utils/tracking";

type IdCardTileProps = {
  policy: CorePolicy;
};

const idTagAction: TagAction = {
  actionName: "ID Card",
  actionType: "button",
};

export const IdCardTile = ({ policy }: IdCardTileProps) => {
  const { policyNumber, country } = policy;
  const vehicleNamesLabel = getVehiclesLabel(policy.vehicles);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const compOnlyDialog = useDialog();
  const {
    download: downloadIdCard,
    isDownloading,
    error: downloadIdCardError,
  } = useDownloadIdCard(policyNumber, country);

  useEffect(() => {
    setIsErrorAlertOpen(downloadIdCardError != undefined);
  }, [downloadIdCardError]);

  return (
    <>
      <Tile
        icon={<img src={IdCard} alt="ID Card" aria-hidden="true" />}
        isLoading={isDownloading}
        className={styles.tile}
        cta="ID Card"
        ariaLabel={`Access Insurance ID for your ${vehicleNamesLabel} on policy ${policyNumber}`}
        tagAction={idTagAction}
        onClick={() =>
          policy.hasIdCard ? downloadIdCard() : compOnlyDialog.open()
        }
      />

      {compOnlyDialog.isOpen && (
        <CompOnlyDialog
          policyName={getStateName(policy.state)}
          compOnlyType={policy.compOnlyType ?? "STANDARD"}
          onClose={() => {
            mt.trackPopupClose();
            compOnlyDialog.close();
          }}
        />
      )}

      {isErrorAlertOpen && (
        <DownloadDocumentErrorAlert
          documentName="ID Card"
          onClose={() => setIsErrorAlertOpen(false)}
        />
      )}
    </>
  );
};
