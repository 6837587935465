import "@hagerty/global-header/dist/index.css";
import {
  GlobalHeader,
  PrimaryHeader,
  BrandingProps,
} from "@hagerty/global-header";
import avatar from "./img/generic-avatar.png";
import style from "./Header.module.scss";
type HeaderProps = {
  firstName?: string;
  lastName?: string;
  hagertyHomeUrl: string;
  logoutUrl: string;
};

export const Header = ({
  firstName = "",
  lastName = "",
  hagertyHomeUrl,
  logoutUrl,
}: HeaderProps) => {
  const branding: BrandingProps = {
    href: hagertyHomeUrl,
  };

  const userMenuOptions = [<a href={logoutUrl}>Log out</a>];

  const user = {
    firstName,
    lastName,
    avatar,
  };

  return (
    <GlobalHeader sticky className={style.container}>
      <PrimaryHeader
        branding={branding}
        user={user}
        userMenuOptions={userMenuOptions}
      />
    </GlobalHeader>
  );
};
