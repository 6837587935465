import { AlertLevel } from "./Alert";
import { ReactComponent as DangerIcon } from "../../svg/danger.svg";
import { ReactComponent as InfoIcon } from "../../svg/info.svg";
import { ReactComponent as TipIcon } from "../../svg/tip.svg";
import { ReactComponent as WarningIcon } from "../../svg/warning.svg";
import { ReactComponent as SuccessIcon } from "../../svg/success.svg";

type AlertIconProps = {
  level: AlertLevel;
};

const levelMap = {
  danger: DangerIcon,
  default: TipIcon,
  info: InfoIcon,
  warning: WarningIcon,
  success: SuccessIcon,
};

export function AlertIcon({ level }: AlertIconProps) {
  const Icon = levelMap[level];
  return <Icon className="alert_icon icon icon_large" />;
}
