type DisplayNameProps = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};

export const getDisplayName = ({
  firstName,
  middleName,
  lastName,
}: DisplayNameProps): string => {
  const parts = [];
  if (firstName) {
    parts.push(firstName);
  }
  if (middleName) {
    parts.push(middleName);
  }
  if (lastName) {
    parts.push(lastName);
  }
  return parts.join(" ");
};
