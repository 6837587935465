import { MutableCustomer } from "shared";
import { decodePhoneNumber } from "../../utils/decodePhoneNumber";

export const formFieldNames = [
  "firstName",
  "middleName",
  "lastName",
  "line1",
  "line2",
  "city",
  "state",
  "postalCode",
  "phone",
] as const;

export const getMutatableCustomer = (
  elements: HTMLFormControlsCollection
): MutableCustomer => {
  const mutatableCustomer: { [key: string]: string } = {};
  formFieldNames.forEach((key: string) => {
    const element = elements.namedItem(key) as HTMLInputElement;
    mutatableCustomer[key] = element.value.trim();
  });

  mutatableCustomer.phone = decodePhoneNumber(mutatableCustomer.phone);
  mutatableCustomer.postalCode = mutatableCustomer.postalCode.toUpperCase();
  return mutatableCustomer as MutableCustomer;
};
