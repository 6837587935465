import styles from "./Card.module.scss";
import cx from "clsx";
import { Link } from "../Link";
import { TagAction } from "../../types/Tag.types";
import { createTagActionClickHandler } from "../../utils/createTagActionClickHandler";
import { ForwardedRef, forwardRef } from "react";
import { Button } from "@hagerty/self-service-components";

export type CardWidth = "small" | "medium" | "full";

interface LinkCard extends SharedCardProps {
  /** Aria-label to apply to the Card's root element */
  ariaLabel?: string;

  /** Href to link to when the card is clicked. If specified, the card is rendered as a Link. Otherwise, card is rendered as a section. */
  href?: string;

  /** Tracking name and action */
  tagAction?: TagAction;

  /** Open href in a new tab when true */
  newTab?: boolean;

  /** onClick can be used with an anchor */
  onClick?: () => void;

  isLoading?: boolean;
}

interface ButtonCard extends SharedCardProps {
  /** Aria-label to apply to the Card's root element */
  ariaLabel?: string;

  /** Function called when the card is clicked. NOTE: This does NOT apply when href is set. */
  onClick?: () => void;

  /** Never since Button doesn't support an href */
  href?: never;
  newTab?: never;

  /** Tracking name and action */
  tagAction?: TagAction;

  isLoading?: boolean;
}

interface DivCard extends SharedCardProps {
  /** Div can't have an aria-label */
  ariaLabel?: never;

  /** Div can't have an href or tagAction */
  href?: never;
  tagAction?: never;
  newTab?: never;

  /** Div can't have an onClick */
  onClick?: never;

  isLoading?: boolean;
}

interface SharedCardProps {
  /** CSS class to apply to the root element */
  className?: string;

  /** Content to display in the card */
  children: React.ReactNode;

  /** Card width */
  width?: CardWidth;
}

type CardProps = DivCard | LinkCard | ButtonCard;

export const Card = forwardRef(
  (
    {
      ariaLabel,
      children,
      width = "small",
      className,
      href,
      onClick,
      newTab = false,
      tagAction,
      isLoading,
    }: CardProps,
    ref: ForwardedRef<any>
  ) => {
    const classes = cx(styles.root, styles[width], className);

    if (isLoading) {
      return (
        <div className={cx(classes, styles.loadingWrapper)}>
          <Button isLoading={true} variant="text" size="xs" />
        </div>
      );
    }
    if (href) {
      return (
        <Link
          ref={ref}
          href={href}
          className={classes}
          ariaLabel={ariaLabel}
          newTab={newTab}
          tagAction={tagAction}
          onClick={onClick}
        >
          {children}
        </Link>
      );
    }

    if (onClick) {
      const tagActionClickHandler = createTagActionClickHandler({
        onClick,
        tagAction,
      });

      return (
        <button
          ref={ref}
          className={classes}
          onClick={(event) => {
            event.currentTarget.focus();
            tagActionClickHandler?.(event);
          }}
          onBlur={(event) => {
            event.currentTarget.blur();
          }}
          aria-label={ariaLabel}
        >
          {children}
        </button>
      );
    }

    return <div className={classes}>{children}</div>;
  }
);
