import { InsuranceLayout } from "./InsuranceLayout";
import { PolicyProps } from "../Policy/Policy.types";
import { InsuranceTiles } from "./InsuranceTiles";
import { VehiclesListCard } from "../PolicyDetails/VehiclesListCard/VehiclesListCard";
import styles from "./InsurancePolicy.module.scss";

/** Renders the "Hagerty Insurance Policy" region */
export const InsurancePolicy = ({ policy }: PolicyProps) => {
  return (
    <InsuranceLayout policy={policy}>
      <InsuranceTiles policy={policy} />
      <VehiclesListCard policy={policy} className={styles.spacing} />
    </InsuranceLayout>
  );
};
