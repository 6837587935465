import { createContext, ReactNode, useRef } from "react";
import { createWrappedContext } from "../../hooks/createWrappedContext";
import { logOnReady, OnReadyPayload } from "../../utils/logOnReady";

type LogOnReadyContext = {
  logHasHdc: (hasHdc: boolean) => void;
  logIsPolicyHolder: (isPolicyHolder: boolean) => void;
  logHasOutstandingBalance: (hasOutstandingBalance: boolean) => void;
  logHasESignDocuments: (hasDocuments: boolean) => void;
};

const Context = createContext<LogOnReadyContext | null>(null);
const useWrappedContext = createWrappedContext<LogOnReadyContext | null>({
  context: Context,
  providerName: "LogOnReadyContextProvider",
});

export type LogOnReadyContextProviderProps = {
  children: ReactNode;
};

/** Provider for global report on ready context */
export const LogOnReadyContextProvider = ({
  children,
}: LogOnReadyContextProviderProps) => {
  const policyRef = useRef<OnReadyPayload>({});

  const valueRef = useRef({
    logHasHdc: (hasHdc: boolean) => {
      policyRef.current.hasHdc = hasHdc;
      logOnReady(policyRef.current);
    },

    logIsPolicyHolder: (isPolicyHolder: boolean) => {
      policyRef.current.isPolicyHolder = isPolicyHolder;
      logOnReady(policyRef.current);
    },

    logHasOutstandingBalance: (hasOutstandingBalance: boolean) => {
      policyRef.current.hasOutstandingBalance = hasOutstandingBalance;
      logOnReady(policyRef.current);
    },

    logHasESignDocuments: (hasESignDocuments: boolean) => {
      policyRef.current.hasESignDocuments = hasESignDocuments;
      logOnReady(policyRef.current);
    },
  });

  return (
    <Context.Provider value={valueRef.current}>{children}</Context.Provider>
  );
};

export const useLogHasHdc = () => {
  const context = useWrappedContext("useLogHasHdc");
  return context.logHasHdc;
};

export const useLogIsPolicyHolder = () => {
  const context = useWrappedContext("useLogIsPolicyHolder");
  return context.logIsPolicyHolder;
};

export const useLogHasOutstandingBalance = () => {
  const context = useWrappedContext("useLogHasOutstandingBalance");
  return context.logHasOutstandingBalance;
};

export const useLogHasESignDocuments = () => {
  const context = useWrappedContext("useLogHasESignDocuments");
  return context.logHasESignDocuments;
};
