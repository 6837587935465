import stateFarmLogoUrl from "./img/state-farm-logo.svg";
import { isStateFarmPartner } from "shared";
import styles from "./PartnerLogo.module.scss";
import { Partner } from "@hagerty/self-service-components/api/types";

type PartnerLogoProps = {
  partner: Partner;
};

export const PartnerLogo = ({ partner }: PartnerLogoProps) => {
  if (isStateFarmPartner(partner)) {
    return (
      <div
        className={styles.container}
        aria-hidden="true"
        aria-label="State Farm logo"
      >
        <img src={stateFarmLogoUrl} alt="State Farm logo" />
      </div>
    );
  }

  return null;
};
