import styles from "./Highlight.module.scss";
import cx from "clsx";

type HighlightProps = {
  /** Aria-label to apply to the surrounding span */
  ariaLabel: string;

  /** Content to highlight in blue */
  children: React.ReactNode;

  /** CSS class to apply to the root element */
  className: string;
};

export function Highlight({ children, ariaLabel, className }: HighlightProps) {
  return (
    <span aria-label={ariaLabel} className={cx(styles.root, className)}>
      {children}
    </span>
  );
}
