import { Dialog, DialogButton, DialogButtonGroup } from "../../Dialog";
import { Link } from "../../Link";
import { TagAction } from "../../../types/Tag.types";
import { mt } from "../../../utils/tracking";
import { useEffect } from "react";
import { CompOnlyType } from "@hagerty/self-service-components/api/types";
import { getCompOnlyMessage } from "@hagerty/self-service-components/api/utils";

const viewOrModifyTagTaction: TagAction = {
  actionType: "link",
  actionName: "view and modify your coverages here",
};

type CompOnlyDialogProps = {
  policyName: string;
  compOnlyType: CompOnlyType;
  managePolicyUrl?: string;
  onClose: () => void;
};

export const CompOnlyDialog = ({
  policyName,
  compOnlyType,
  managePolicyUrl,
  onClose,
}: CompOnlyDialogProps) => {
  useEffect(() => mt.trackPopup("comp only"), []);

  const closeDialog = () => {
    mt.trackPopupClose();
    onClose();
  };

  return (
    <Dialog
      title="This policy doesn't have an ID card"
      footer={
        <DialogButtonGroup>
          <DialogButton onClick={closeDialog} variant="primary" aria-hidden>
            Got it
          </DialogButton>
        </DialogButtonGroup>
      }
      onDismiss={closeDialog}
    >
      <p>
        {getCompOnlyMessage(policyName, compOnlyType)}
        {managePolicyUrl && (
          <>
            {" "}
            You can{" "}
            <Link
              href={managePolicyUrl}
              tagAction={viewOrModifyTagTaction}
              ariaLabel="view and modify your coverages"
            >
              view and modify your coverages here
            </Link>
            {"."}
          </>
        )}
      </p>
    </Dialog>
  );
};
