// converts a local date string to a display date string
// e.g. 2021-04-27 => Apr 27, 2021

// Due to a suspected V8 change in Node, we are ignoring the country and are formatting
// US and Canadian dates in "en" locale.
// see discussion: https://hagerty-dit.slack.com/archives/C01A7CU87C5/p1645049688319459
export const formatDateISO = (dateISO: string): string => {
  const arr = /^(\d\d\d\d)-(\d\d)-(\d\d)(T.*)?$/.exec(dateISO);
  if (!arr) {
    return dateISO;
  }
  const [, yyyy, mm, dd] = arr;
  const date = new Date(
    parseInt(yyyy, 10),
    parseInt(mm, 10) - 1,
    parseInt(dd, 10)
  );

  // NOTE: We used to use { dateStyle: "medium" } but that was displaying on mobile as M/D/YYYY
  // see https://dev.azure.com/hagerty/Hagerty/_workitems/edit/81337
  return new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(date);
};
