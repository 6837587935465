import clsx from "clsx";
import React, { ForwardedRef } from "react";
import { TagAction } from "../../types/Tag.types";
import { Link } from "../Link";
import "./Button.scss";
import { mt } from "../../utils/tracking";

export interface ButtonProps
  extends Partial<
    React.ButtonHTMLAttributes<HTMLButtonElement> &
      Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>>
  > {
  /** Button size */
  size?: "medium" | "large";

  /** Determines whether tag is rendered as a button or a link  */
  href?: string;

  /** Optional tag to send to the tracking system */
  tagAction?: TagAction;

  /** Set to true to open link in a new tab */
  newTab?: boolean;

  /** Aria-label to apply to the anchor. Accepting here since it's easier to pass than aria-label. */
  ariaLabel?: string;

  /** Button style */
  variant?:
    | "primary"
    | "primary-black"
    | "secondary"
    | "specialized"
    | "outline"
    | "icon";
}

/** Button with multiple sizes and variants */
export const Button = React.forwardRef(
  (
    {
      children,
      onClick,
      href,
      tagAction,
      newTab,
      size = "medium",
      variant = "primary",
      className = "",
      ariaLabel,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement & HTMLAnchorElement>
  ) => {
    // Oddly, outline's class is `button-outline`, while all other buttons
    // use an underscore (e.g. `button_primary`). This accounts for that.
    const classDivider = variant === "outline" ? "-" : "_";

    // Since the HDS button doesn't support a black variant, use the primary variant styles as a baseline for the primary-black variant.
    const cssSelector = variant === "primary-black" ? "primary" : variant;

    const classes = clsx(
      "button",
      // "focus-visible" will prevent `outline: none` from being applied to a primary button.
      // We do this as a primary button has a background color of white when focused so we need
      // to retain the outline from the HDS. This fixes #112259
      { "focus-visible": variant === "primary" },
      `button${classDivider}${cssSelector}`,
      `button${classDivider}${cssSelector}--${size}`,
      className,
      { "button--black": variant === "primary-black" }
    );

    return href ? (
      <Link
        ref={ref}
        onClick={onClick}
        className={classes}
        ariaLabel={ariaLabel}
        href={href}
        tagAction={tagAction}
        newTab={newTab}
        {...rest}
      >
        {children}
      </Link>
    ) : (
      <button
        ref={ref}
        onClick={
          onClick
            ? (ev) => {
                if (tagAction) mt.trackAction(tagAction);
                onClick(ev);
              }
            : undefined
        }
        className={classes}
        aria-label={ariaLabel}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
