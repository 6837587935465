import { TagAction } from "../types/Tag.types";
import { mt } from "./tracking";

type MouseEventHandler = (event: React.MouseEvent<any, MouseEvent>) => void;

type WrapOnClickProps = {
  tagAction?: TagAction;
  onClick?: MouseEventHandler;
};

export const createTagActionClickHandler = ({
  onClick,
  tagAction,
}: WrapOnClickProps): MouseEventHandler | undefined => {
  const wrappedClickProp = tagAction
    ? (event: React.MouseEvent<any, MouseEvent>) => {
        mt.trackAction(tagAction);

        // also invoke the caller's onClick handler if it exists
        if (onClick) onClick(event);
      }
    : onClick;

  return wrappedClickProp;
};
