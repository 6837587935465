import React, { ForwardedRef } from "react";
import { TagAction } from "../../types/Tag.types";
import { createTagActionClickHandler } from "../../utils/createTagActionClickHandler";
import styles from "./Link.module.scss";
import clsx from "clsx";

// Extend React Link element to eliminate need for specifying the various props it includes below.
type LinkBaseProps = React.HTMLProps<HTMLAnchorElement> & {
  /** Aria-label to apply to the anchor. Accepting here since it's easier to pass than aria-label. */
  ariaLabel?: string;

  /** Custom CSS class */
  className?: string;

  /** Link href. Specifying here so it's required because it's optional on HTMLAnchorElement. */
  href: string;

  newTab?: boolean;

  /** Optional tag to send to the tracking system */
  tagAction?: TagAction;

  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;

  isLoading?: boolean;

  phoneNumber?: never;
};

type LinkPhoneNumberProps = {
  /** If specified will render a "tel:" href */
  phoneNumber: string;

  href?: never;
  newTab?: never;
} & Omit<LinkBaseProps, "href" | "phoneNumber" | "newTab">;

type LinkProps = LinkBaseProps | LinkPhoneNumberProps;

/** Renders an external link. Replaces `{ENV}` or `{CLOUD_ENV}` in href prop with the current environment. */
export const Link = React.forwardRef(
  (
    {
      ariaLabel,
      newTab = false,
      tagAction,
      phoneNumber,
      children = phoneNumber,
      href,
      onClick,
      className,
      isLoading = false,
      ...rest
    }: LinkProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const linkProps = newTab
      ? {
          target: "_blank",
          rel: "noopener noreferrer",
        }
      : {};

    const tagActionClickHandler = createTagActionClickHandler({
      onClick,
      tagAction,
    });

    const dialablePhoneNumber =
      phoneNumber &&
      (!phoneNumber.startsWith("1") ? `1-${phoneNumber}` : phoneNumber);

    return (
      <a
        {...rest}
        {...linkProps}
        className={clsx(className, { [styles.linkLoading]: isLoading })}
        ref={ref}
        onClick={tagActionClickHandler}
        href={dialablePhoneNumber ? `tel:+${dialablePhoneNumber}` : href}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }
);
