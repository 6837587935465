import { Policy } from "@hagerty/self-service-components/api/types";
import Warning from "../../../svg/warning.svg";
import { Dialog } from "../../Dialog";
import { Button } from "@hagerty/self-service-components";
import style from "./RemoveLastVehicleDialog.module.scss";
import { Link } from "../../Link";
import { useFeatureFlag, useUrls } from "../../../app/Providers/AppContext";
import { TagAction } from "../../../types/Tag.types";
import { mt } from "../../../utils/tracking";
import { useEffect, useState } from "react";

const cancelPolicyTagAction: TagAction = {
  actionName: "Cancel policy",
  actionType: "link",
};
const addVehicleTagAction: TagAction = {
  actionName: "Add a new vehicle",
  actionType: "button",
};

type RemoveLastVehicleDialogProps = {
  policy: Policy;
  onClose: () => void;
};

export const RemoveLastVehicleDialog = ({
  policy,
  onClose,
}: RemoveLastVehicleDialogProps) => {
  const [redirectAddVehicle, setRedirectAddVehicle] = useState(false);
  const [redirectCancelPolicy, setRedirectCancelPolicy] = useState(false);
  const urls = useUrls();
  const { ADD_VEHICLE } = useFeatureFlag();
  const cancelPolicyUrl = urls.insurance.cancelPolicy(policy.country);
  const addVehicleUrl = urls.insurance.addVehicle(
    policy.policyNumber,
    policy.country,
    ADD_VEHICLE.treatment
  );

  useEffect(() => mt.trackPopup("Cancel policy"), []);

  const closeDialog = () => {
    mt.trackPopupClose();
    onClose();
  };

  const addVehicleOnClick = () => {
    mt.trackAction(addVehicleTagAction);
    setRedirectAddVehicle(true);
  };

  return (
    <Dialog
      size="medium"
      label="Last vehicle on policy dialog"
      onDismiss={closeDialog}
    >
      <div className={style.dialogBodyWrapper}>
        <img width={48} src={Warning} alt="Warning" aria-hidden="true" />
        <div className={style.dialogTitle}>Remove vehicle</div>
        <div className={style.dialogMessage}>
          You may not remove the last vehicle on your policy. To keep this
          policy you will need to add another vehicle prior to removing this
          vehicle.
        </div>
        <div className={style.dialogActions}>
          <Button
            color="primary"
            as="a"
            href={addVehicleUrl}
            aria-label={`Add a new vehicle to policy ${policy.policyNumber}`}
            onClick={addVehicleOnClick}
            isLoading={redirectAddVehicle}
          >
            Add a new vehicle
          </Button>
        </div>
        <div className={style.dividerWrapper}>
          <div className="divider" />
        </div>
      </div>
      <div className={style.dialogFooter}>
        Don't have another vehicle to add?
        <Link
          className={style.cancelPolicyLink}
          href={cancelPolicyUrl}
          aria-label={`Cancel policy ${policy.policyNumber}`}
          tagAction={cancelPolicyTagAction}
          onClick={() => setRedirectCancelPolicy(true)}
          isLoading={redirectCancelPolicy}
        >
          Cancel policy
        </Link>
      </div>
    </Dialog>
  );
};
