/**
 * Parses a timeout environment variable value to a number.
 * Returns the default value if the input is undefined or not valid.
 */
export function parseTimeoutEnvVar(
  value: string | undefined,
  defaultValue: number,
): number {
  // Note: if s is invalid the result will be NaN and defaultValue will be returned
  return (value ? parseInt(value, 10) : defaultValue) || defaultValue;
}
