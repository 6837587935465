import { Bills } from "@hagerty/self-service-components/payments";
import { useBillingPayment } from "./useBillingPayment";
import styles from "./BillingPayment.module.scss";
import { phoneNumbers } from "shared";

export const heading = "Upcoming Bills";

export const BillingPayment = () => {
  const { apexBills, coreBills } = useBillingPayment();

  return (
    <div className={styles.container}>
      <Bills
        isLoading={false}
        bills={[...coreBills, ...apexBills]}
        apexBillPhoneNumber={{
          href: `tel:${phoneNumbers.apex}`,
          formatted: phoneNumbers.apex,
        }}
        returnButtonLabel="Return to Account Dashboard"
        returnUrl={window.location.href}
      />
    </div>
  );
};
